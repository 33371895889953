<template>
  <a class="wd_phone">
    <div class="wd_phone_border wd_phone_border_bottom wd_phone_border_bottom_1"/>
    <div class="wd_phone_border wd_phone_border_bottom wd_phone_border_bottom_2"/>
    <div class="wd_phone_border wd_phone_border_bottom wd_phone_border_bottom_3"/>
    <div class="wd_phone_border wd_phone_border_bottom wd_phone_border_bottom_4"/>
    <div class="wd_phone_border wd_phone_border_top wd_phone_border_top_1"/>
    <div class="wd_phone_border wd_phone_border_top wd_phone_border_top_2"/>
    <div class="wd_phone_border wd_phone_border_top wd_phone_border_top_3"/>
    <div class="wd_phone_border wd_phone_border_top wd_phone_border_top_4"/>
    <slot/>
  </a>
</template>

<script>
  export default {
    name: 'wd-phone'
  }
</script>

<style lang="scss">
  .wd_phone {
    display: inline-block;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 2;
    text-transform: uppercase;
    color: $primary;
    position: relative;
    transition: .2s;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: $primary-light;

      .wd_phone_border {
        background: $secondary-light;
      }

      .wd_phone_border_top_1,
      .wd_phone_border_bottom_1 {
        width: 35%;
      }

      .wd_phone_border_top_2 {
        width: 2%;
        right: 45%;
      }

      .wd_phone_border_bottom_2 {
        width: 16%;
        left: 58%;
      }

      .wd_phone_border_top_3 {
        width: 30%;
        right: 54%;
      }

      .wd_phone_border_bottom_3 {
        width: 5%;
        left: 80%;
      }

      .wd_phone_border_top_4 {
        width: 10%;
        right: 90%;
      }

      .wd_phone_border_bottom_4 {
        width: 2%;
        left: 98%;
      }
    }
  }

  .wd_phone_border {
    transition: .2s;
    height: 1px;
    width: 30%;
    position: absolute;
    background: $primary;
  }

  .wd_phone_border_top {
    right: 0;
    top: 0;
  }

  .wd_phone_border_bottom {
    left: 0;
    bottom: 0;
  }
</style>
