<template>
    <svg class="wd-arrow" fill="transparent"
        :class="{back: mode === 'back', collapse: mode === 'collapse', partial: mode === 'partial'}" :style="{'--hoverColor': hColor}"
        :width="size" :height="size" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 8L16 20.1181L24 32.2362" :stroke="color" :class="{flip: value}"/>
    </svg>
</template>

<script>
    export default {
        name: "WdArrow",
        props: {
            color: {
                type: String,
                default: 'white'
            },
            hoverColor: {
                type: String,
                default: null
            },
            mode: {
                type: String, // back | collapse | partial
                default: 'collapse'
            },
            value: {
                type: Boolean,
                default: true
            },
            size: Number
        },
        computed: {
            hColor() {
                return this.hoverColor ? this.hoverColor : this.color;
            }
        }
    }
</script>

<style scoped lang="sass">

    svg
        cursor: pointer

        &.back:hover path, &.back.hover path
            transform: scaleX(2) scaleY(0.666)

        &:hover > *
            stroke: var(--hoverColor)

        &.collapse
            path
                transform: rotate(90deg)
            path.flip
                transform: rotate(270deg)

        &.partial
            path
                transform: rotate(90deg)
            path.flip
                transform: rotate(180deg)

        path
            transform-origin: center
            transition: all 0.5s
            transform: none
</style>
