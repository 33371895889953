<template>
  <div class="another-portfolio" :style="{'--direction': direction}">
    <div class="nav-holder">
      <div class="arrow">
        <transition name="slide-switch">
          <wd-arrow color="#A78733" mode="back" :key="`prev-${keyLeft}`" :size="32" @click.native="prev"/>
        </transition>
      </div>
      <div class="center">
        <transition name="slide-switch">
          <span :key="`name-${keyLeft + keyRight}`">{{ name }}</span>
        </transition>
      </div>
      <div class="arrow">
        <transition name="slide-switch">
          <wd-arrow class="next" color="#A78733" mode="back" :key="`next-${keyRight}`" :size="32" @click.native="next"/>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import WdArrow from "@/components/ui-kit/wd-arrow";
export default {
name: "PortfolioNavigation",
  components: {WdArrow},
  data: () => ({
    direction: 1,
    keyLeft: 0,
    keyRight: 0
  }),
  props: {
    name: String
  },
  methods: {
    prev() {
      this.direction = 1;
      this.keyLeft++;
      this.$emit('prev')
    },
    next() {
      this.direction = -1;
      this.keyRight++;
      this.$emit('next')
    }
  }
}
</script>

<style scoped lang="scss">

// TRANSITIONS

.slide-switch-leave-active {
  transition: transform $transition-duration, opacity $transition-duration, max-width $transition-duration !important;
}
.slide-switch-enter-active {
  transition-delay: 0.3s;
  transition: transform $transition-duration, opacity $transition-duration, max-width $transition-duration !important;
}
.slide-switch-enter {
  transform: translate(calc(-10rem * var(--direction)), 0) !important;
  opacity: 0;
  &.wd-arrow.next {
    transform: rotate(180deg) translate(calc(10rem * var(--direction)), 0) !important;
  }
}
.slide-switch-leave-to {
  transform: translate(calc(10rem * var(--direction)), 0) !important;
  opacity: 0;
  &.wd-arrow.next {
    transform: rotate(180deg) translate(calc(-10rem * var(--direction)), 0) !important;
  }
}

// TODO jumping on animation end =(

.another-portfolio {
  grid-area: another;
  align-self: center;
  justify-self: end;
  position: relative;
  top: -0.8rem;
  z-index: 5;


  > div.nav-holder {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 20rem;


    > div {
      height: 3.2rem;
      position: relative;

      > :nth-child(2) {
        position: absolute;
        left: 0;
        top: 0;
      }

      &.center {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        overflow: hidden;

        &::after {
          content:'';
          width: 1rem;
          height:100%;
          position:absolute;
          right:0;
          top:0;
          background:linear-gradient(to right, transparent, rgb(var(--bg)));
        }

        span {
          width: fit-content;
        }
      }
    }
  }

  span {
    color: $secondary;
    font-size: 1.2rem;
    line-height: 200%;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    //max-width: 100%;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    transition: 0.5s;
    height: 32px;
    white-space: nowrap;
    user-select: none;

    &.slide-switch-leave-to, &.slide-switch-enter {
      //max-width: 0;
    }

    &:hover {
      transform: none !important;
    }
  }

  &:hover {
    span {
      transform: translateX(0.8rem);
    }
  }
  .wd-arrow {
    transition: all $transition-duration;
  }
  .arrow {
    &:hover ~ div span {
      transform: translateX(-0.8rem)
    }

    .next {
      transform: rotate(180deg);
    }
  }

  @include mobile {
    > div.nav-holder {
      width: 18rem;
      margin-left: -1rem;
      pointer-events: all;

      .center {
        max-width: 11.6rem;
      }
    }
  }
}

</style>
