export default {
  router: {
    home: 'Startseite',
    about: 'Über uns',
    services: 'Leistungen',
    contacts: 'Kontakt',
    portfolio: 'Portfolio'
  },
  app: {
    portfolio: 'Portfolio'
  },
  dialog: {
    title: 'Project starten',
    name: 'Name',
    phone: 'Rufnummer',
    email: 'E-mail',
    description: 'Ihre Nachricht',
    submit: 'Absenden',
    close: 'Schließen',
    back: 'Zurück'
  },
  orderNowButton: 'Starten',
  uiKit: {
    file: {
      caption: 'Datei anhängen'
    },
    fileList: {
      label: 'Anhänge'
    },
    input: {
      error: {
        required: 'Dieses Feld ist erforderlich',
        email: 'E-Mail entspricht nicht dem Format'
      }
    }
  },
  home: {
    occupations: [
      'Webseite',
      'Design',
      'Beförderung',
      'Chatbots'
    ],
    brandName: 'WEDO',
    subtitle: 'Wir machen alles was Sie brauchen'
  },
  about: {
    view1: {
      title: 'Wir schaffen Outsourcing-Lösungen für Ihr Geschäft',
      technology: {
        title: 'Technologien',
        list: [
          'Wir schaffen adaptives Design',
          'Unsere Programmierer benutzen Laravel und Yii',
          'Wir gestalten und animieren mit HTML5 + CSS3',
          'Frontend machen wir auf Vue.js, React, Angular'
        ]
      },
      clients: {
        title: 'Unsere Kunden',
        description: 'Wir arbeiten mit anspruchsvollen Unternehmen zusammen - wir schaffen Lösungen für sie und verbessern die bestehende Projekte. ' +
          'Mit uns realisieren Sie Ihr Projekt genau so, wie Sie es beabsichtigt haben'
      }
    },
    view2: {
      title: 'Wir schaffen',
      description: 'Webseite, Services, Verwaltungssysteme, Treueprogramme, Bots bei Facebook Messenger, WhattsSpp und Skype. ' +
        'Basierend auf der Analyse Ihres Unternehmens sind wir bereit, das Produkt über den gesamten Lebenszyklus hinweg zu unterstützen - von der Schaffung der Marke bis hin zur Beförderung'
    },
    view3: {
      title: 'Hauptkompetenzen',
      competitions: {
        1: 'Chatbots',
        2: 'Frontend',
        3: 'Backend',
        4: 'UI/UX-Design',
        5: 'Prototyping',
        6: 'UX-Beratung'
      }
    }
  },
  services: {
    serviceItem: {
      priceFrom: 'ab'
    },
    title: 'Folgendes können wir für Sie tun',
    sections: [{
      caption: 'Webentwicklung',
      items: [{
        title: 'Chatbotsentwiklung',
        price: 1000,
        text: 'Intelligente und freundliche Messenger-Bots ' +
          'sparen Ihre Kosten sowie die Zeit ihrer Support-Mitarbeiter und automatisieren Ihre Verkaufstrichter'
      }, {
        title: 'Firmenseiten',
        price: 1500,
        text: 'Website für Unternehmen jeglichen Niveaus, die dabei hilft, ' +
          'sich zu beweisen und Ihre geschäftlichen Probleme zu lösen'
      }, {
        title: 'Zielseite',
        price: 1000,
        text: 'Auf der Zielseite der Werbekampagne können Besucher gesammelt ' +
          'und zu Käufern gemacht werden'
      }, {
        title: 'Online-Shops',
        price: 5000,
        text: 'Bequemer Online-Shop mit übersichtlicher Navigation finden wir ' +
          'für den Start des digitalen Verkaufs unverzichtbar'
      }, {
        title: 'Entwicklung von Spezifikationen',
        price: 200,
        text: 'Unser Experte verwandelt Ihre Wünsche in eine spezifische und verständliche technische Aufgabe, ' +
          'erstellt eine Struktur der zukünftigen Ressource sowie eine Liste richtig eingestellten Aufgaben und berät Entwickler'
      }]
    }, {
      caption: 'Design',
      items: [{
        title: 'Markenbildung',
        price: 700,
        text: 'Ein Logo mit einer Bedienungsanleitung mit Darstellungen auf ' +
          'Informationsmedien hilft Ihrem Unternehmen, (wieder)geboren zu werden'
      }, {
        title: 'UI/UX-Design',
        price: 500,
        text: 'Hochwertiges, praktisches UX-Design, mit dessen Hilfe die Site ' +
          'Ihre geschäftlichen Probleme löst'
      }, {
        title: 'Grafikdesign',
        price: 100,
        text: 'Grafische Elemente, Illustrationen, typografisches Layout, ' +
          'Visitenkarten, Flyer, Banner - was auch immer Ihr Herz begehrt'
      }]
    },

    //   {
    //   caption: 'Marketing',
    //   items: [{
    //     title: 'SEO',
    //     price: 1000,
    //     text: 'Wir helfen Suchmaschinen und Ihrer Website, einander zu finden. ' +
    //       'Semantik-Sammlung, Konkurrenzanalyse, Kauf von Links, SEO-Optimierung der Seite und vieles mehr'
    //   }, {
    //     title: 'SEO-Beratung',
    //     price: 400,
    //     text: 'Unser SEO-Spezialist analysiert die Architektur, den Inhalt und ' +
    //       'die Linkstruktur der Website und hilft bei der Beseitigung von Fehlern, die das Wachstum der Website behindern'
    //   }, {
    //     title: 'PPC',
    //     price: 1000,
    //     text: 'Aufbau einer Kampagnenstrategie, Unterstützung und Beratung'
    //   }, {
    //     title: 'Audit von Werbekampagnen',
    //     price: 400,
    //     text: 'Durch eine umfassende Analyse und Überprüfung Ihrer AdWords- und ' +
    //       'Facebook-Kampagnen sparen Sie bis zu 60% Ihrer Werbekosten'
    //   }]
    // }

    ]
  },
  contacts: {
    title: 'Lassen Sie uns die beste Lösung für Sie erstellen.',
    startProject: 'Project starten',
    ourPresentation: 'Unsere Präsentation',
    description: [
      'Wir sind ein Unternehmen der neuen Generation. Wir haben uns gefunden, um im Bereich der Webentwicklung zusammenzuarbeiten.',
      'Zu Ihrer Verfügung steht ein engmaschiges Team talentierter Spezialisten sowie eine erfolgreiche Strategie für Webentwicklung und digitales Marketing.',
      'Outsourcing - und das wars!'
    ],
    phoneUkraine: 'Ukraine',
    phoneGermany: 'Deutschland',
    thankYou: {
      title: 'Ein guter Anfang!',
      sections: [{
        title: 'Jetzt haben Sie Ihren persönlichen Manager',
        text: 'Ein Ihnen zugewiesene Account Manager wird Sie kontaktieren, um alle Details zu erfahren.'
      }, {
        title: 'Wir schätzen Ihre Bestellung',
        text: 'Wir freuen uns, dass Sie uns kontaktiert haben. Wir werden alle Anstrengungen unternehmen, um sicherzustellen, dass Ihr Projekt die von anderen Entwicklern zugelassenen Fehler vermeidet.'
      }],
      button: 'Bis bald!'
    }
  },

  seo: {
    home: {
      title: 'WeDo – Webseite, Chatbots entwickeln lassen, Web-design',
      metaTags: [{
        name: 'description',
        content: 'WeDo ⇒ Wir entwickeln und unterstützen Web-Projekte. 👩‍💻 Chatbot-Entwicklung für ' +
          'Facebook Messenger, Telegram, Skype. 🤖 Grafikdesign, UI/UX-Design, Beratung'
      }]
    },

    about: {
      title: 'WeDo – Über uns',
      metaTags: [{
        name: 'description',
        content: 'Wir betrachten Design und Entwicklung als Kunst. 🎭 Webseite, Services, ' +
          'Treueprogramme, Verwaltungssysteme.'
      }]
    },

    services: {
      title: 'WeDo – Leistungen',
      metaTags: [{
        name: 'description',
        content: 'WeDo bietet Grafikdesign, UI/UX-Design, Webseite- und Zielseite-Design an. 🎨' +
          'Digitale Marketing-Dienstleistungen umfassen SEO, SEO-Audit, PPC 🌐'
      }]
    },

    contacts: {
      title: 'WeDo – Kontakt',
      metaTags: [{
        name: 'description',
        content: 'WeDo Unternehmenskontakte. 📞 Rufen Sie uns an +380(99) 60-98-701. 📧 Schreiben Sie uns: sales@wedo.in.ua'
      }]
    },

    portfolio: {
      title: 'WeDo – Portfolio',
      metaTags: [{
        name: 'description',
        content: 'WeDo has experience in graphic design, UI/UX-design, website and landing page design' +
            'as well as chatbot development 🤖 and general web development 🌐'
      }]
    },

    portfolioItem: {
      title: 'WeDo – Portfolio', // TODO finish this
      metaTags: [{
        name: 'description',
        content: 'WeDo has experience in graphic design, UI/UX-design, website and landing page design' +
            'as well as chatbot development 🤖 and general web development 🌐'
      }]
    }
  }
}
