import Vue from 'vue'
import Vuex from 'vuex'

import portfolio from "./portfolio";
import ui from "./ui";
import bot from "./bot";

Vue.use(Vuex)

export default new Vuex.Store({
    modules:{
        portfolio,
        ui,
        bot
    },
    state: {

    },
    actions: {
        request({commit}, payload) {
            const {promise, commitType, customData, namespace} = payload;
            return promise
                .then(response => {
                    if (response.status !== 200) {
                        console.warn(response.text);
                        return;
                    }
                    return response.data;
                })
                .then(data => {
                    commit(namespace + '/' + commitType, customData ? customData : data);
                    return data;
                })
                .catch(err => {
                    console.error("Fetch Error: ", err);
                });
        }
    }
})
