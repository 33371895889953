import structures from "./structures";

// pl.formatLocale.months = pl.formatLocale.months.map(item => item[0].toUpperCase() + item.slice(1))
// import 'moment/locale/pl'  // without this line it didn't work
export default {


    monthList: state => state.period
            ? state.period.map(month => {
                const date = new Date(Date.parse(month.replace(/(\d\d)-(\d\d)-(\d\d\d\d)/, '$2-$1-$3')))
                return `${date.toLocaleString('uk-UA', { month: 'long' }).substr(0, 3)} ${date.getFullYear()}`
            })
            : [],

    serversList: state => state.servers.filter(
        function(item){
            if(state.selectServer == "All"){
                return true
            }
            return item.server === state.selectServer

        }
    )

}
