<template>
  <div class="thank_you_container">
    <div class="thank_you">
      <div class="thank_you_title">
        {{ $t('contacts.thankYou.title') }}
      </div>
      <div
        :key="index"
        class="thank_you_section"
        v-for="(section, index) in $t('contacts.thankYou.sections')"
      >
        <div class="thank_you_section_title">
        <span class="thank_you_section_counter">
          {{ index + 1 }}.
        </span>
          {{ section.title }}
        </div>
        <div class="thank_you_section_text">
          {{ section.text }}
        </div>
      </div>

      <wd-button
        @click="$emit('close')"
        class="thank_you_button"
      >
        {{ $t('contacts.thankYou.button') }}
      </wd-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'thank-you-page'
  }
</script>

<style lang="scss">
  .thank_you_container {
    padding-top: 14rem;
    background: rgb(var(--bg));
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;

    @include mobile {
      padding-top: 4rem;
    }
  }

  .thank_you {
    margin: auto;
    max-width: 76.8rem;

    @include mobile {
      padding: 0 2rem;
    }
  }

  .thank_you_title {
    font-weight: 300;
    font-size: 3.6rem;
    line-height: 1.3;
    letter-spacing: 0.08em;
    color: $secondary;
    margin-bottom: 2.4rem;

    @include mobile {
      font-size: 2.4rem;
      margin-bottom: 3.2rem;
    }
  }

  .thank_you_section {
    margin-bottom: 2.4rem;

    @include desktop {
      padding-left: 4.8rem;
      padding-right: 27.2rem;
    }

    @include mobile {
      margin-bottom: 3.2rem;
    }
  }

  .thank_you_button {
    @include desktop {
      margin-left: 4.8rem;
    }
  }

  .thank_you_section_title {
    font-size: 2.4rem;
    line-height: 1.3;
    letter-spacing: 0.08em;
    color: $secondary-light;

    @include mobile {
      font-size: 1.6rem;
    }
  }

  .thank_you_section_text {
    margin-top: 1.6rem;
    font-size: 1.4rem;
    line-height: 1.7;
    color: $primary-light;
  }

  .thank_you_section_counter {
    @include desktop {
      padding-right: 2.4rem;
    }
  }
</style>
