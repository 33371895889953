<template>
  <div>
    <div class="about_title">
      {{ $t('about.view2.title') }}
    </div>
    <div class="about_text about_content">
      {{ $t('about.view2.description') }}
    </div>

    <mobile-arrow
      @click.native="$emit('next-page', 3)"
      key="about2"
    />
  </div>
</template>

<script>
  import MobileArrow from '../../mobile-arrow'

  export default {
    name: 'page-2',

    components: {
      MobileArrow
    }
  }
</script>

<style lang="scss">
  .about_text {
    padding-left: 6.4rem;
    padding-top: 3.4rem;
    color: $primary-light;
    font-size: 1.4rem;
    line-height: 1.7;
    max-width: 53rem;

    @include mobile {
      padding-left: 3rem;
    }
  }
</style>
