<template>
    <router-link :to="{ name: 'portfolio', params: {lang: $route.params.lang} }" class="back mobile-disappearing">
        <wd-arrow mode="back" class="hover portfolio-back btn" color="#A78733" :size="32" v-if="isMobile"/>
        <wd-link class="portfolio-back btn" :align="`right`" v-else>{{ $t("dialog.back") }}</wd-link>
    </router-link>
</template>

<script>
import portfolioControl from "@/mixins/portfolioControl";

export default {
    name: "PortfolioBackControl",
    mixins: [portfolioControl]
}
</script>

<style scoped lang="scss">
@include mobile {
    .portfolio-back {
        position: fixed;
        top: 1.5rem;
        z-index: 3;
        left: 2.6rem;
    }
}
</style>
