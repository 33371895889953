<template>
  <div class="portfolio-container" @wheel="onWheel">
    <router-link :to="{name: 'home'}">
      <wd-link class="portfolio-close" v-show="!mobileMenuOpened" :class="`transition-${transitionMode}`" :align="`right`">
        {{ $t("dialog.close") }}
      </wd-link>
    </router-link>
    <h2 class="portfolio-title title" v-show="!mobileMenuOpened" :class="`transition-${transitionMode}`">{{ $t("app.portfolio") }}</h2>
    <div class="carousel">
      <div class="carousel-block" :style="{ left: `${position}px` }">
        <PortfolioItem
            v-for="item in portfolioList"
            :key="item.id"
            :data="item"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import PortfolioItem from "@/components/sections/portfolio/PortfolioItem";

// TODO animate v-show for buttons

export default {
  components: {
    PortfolioItem,
  },
  data() {
    return {
      position: 0,
      stepMove: 50,
      carouselWidth: 0,
      transitionMode: 'normal'
    };
  },
  methods: {
    initPosition() {
      this.getCarouselSize();
      this.position = (window.innerWidth - this.carouselWidth) / 2;
    },
    getCarouselSize() {
      const carousel = document.querySelector(".carousel-block");
      this.carouselWidth = carousel.offsetWidth;
    },

    onWheel(ev) {
      if (ev.deltaY > 0 && this.position < 0) {
        this.position += this.stepMove;
      } else if (
        ev.deltaY < 0 &&
        this.position > window.innerWidth - this.carouselWidth
      ) {
        this.position -= this.stepMove;
      }
    },
  },
  computed: {
    ...mapGetters("portfolio", ["portfolioList"]),
    ...mapState("ui", ["mobileMenuOpened"]),
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === 'portfolioItem')
        vm.transitionMode = 'portfolio';
      else vm.transitionMode = 'normal';
    });
  },
  mounted() {
    window.addEventListener("resize", this.getCarouselSize);
    this.unwatch = this.$store.watch(
        (state, getters) => state.portfolio.portfolioList,
        (newValue, oldValue) => {
          this.initPosition();
        },
    );
    this.initPosition();
  },
  beforeDestroy() {
    this.unwatch();
    window.removeEventListener("resize", this.getCarouselSize);
  }
};
</script>

<style lang="scss" scoped>
.portfolio-container {
  padding: 14rem 0;
  display: flex;
  flex-direction: column;
  width: 100vw;
  position: absolute;
  left: calc(100% - 100vw);
  height: 100vh;
  z-index: 2;

  .title {
    margin: 2.6rem 0 2.6rem 21rem;
    font-weight: 300;
    font-size: 3.6rem;
    line-height: 1.33;
    color: $secondary;
    letter-spacing: 0.08em;
    user-select: none;

    @include mobile {
      font-size: 2.4rem;
    }
  }

  .carousel {
    width: 100%;
    height: 22rem;
    position: relative;

    .carousel-block {
      display: flex;
      position: absolute;
    }
  }

  .portfolio-close {
    position: absolute;
    font-weight: 300;
    font-size: 1.2rem;
    color: $secondary;
    right: 8rem;
    top: 7.3rem;
  }

  @include mobile {
    padding: 0;
    width: 100%;
    position: static;

    h2.title {
      font-size: 1.2rem;
      letter-spacing: 0.16em;
      line-height: 2;
      font-weight: 300;
      text-transform: uppercase;
      position: fixed;
      left: 2.6rem;
      top: 1.5rem;
      z-index: 3;
      margin: 0;
    }

    .portfolio-close {
      position: fixed;
      top: 1.5rem;
      z-index: 3;
      right: 5rem;
    }

    .carousel {
      margin-top: 5rem;
      height: initial;
      position: static;

      .carousel-block {
        position: static;
        width: 100%;
        flex-direction: column;

        .item-container {
          margin: 0 0 4rem;
        }
      }
    }
  }
}
</style>
