<template>
  <transition name="dialog-transition">
    <div class="dialog" v-if="$root.dialogVisible">
      <wd-link
        @click.native="$root.showDialog(false)"
        align="right"
        class="dialog_close"
      >
        {{ $t('dialog.close') }}
      </wd-link>
      <div class="dialog_close_border">
        <div class="dialog_border dialog_border_top dialog_border_top_1"/>
        <div class="dialog_border dialog_border_top dialog_border_top_2"/>
        <div class="dialog_border dialog_border_top dialog_border_top_3"/>
        <div class="dialog_border dialog_border_top dialog_border_top_4"/>
        <div class="dialog_border dialog_border_top dialog_border_top_5"/>

        <div class="dialog_border dialog_border_right dialog_border_right_1"/>
        <div class="dialog_border dialog_border_right dialog_border_right_2"/>
        <div class="dialog_border dialog_border_right dialog_border_right_3"/>
        <div class="dialog_border dialog_border_right dialog_border_right_4"/>
        <div class="dialog_border dialog_border_right dialog_border_right_5"/>
      </div>

      <div class="dialog_border dialog_border_right dialog_border_right_6"/>
      <div class="dialog_border dialog_border_right dialog_border_right_7"/>
      <div class="dialog_border dialog_border_right dialog_border_right_8"/>
      <div class="dialog_border dialog_border_right dialog_border_right_9"/>

      <div class="dialog_border dialog_border_bottom dialog_border_bottom_1"/>
      <div class="dialog_border dialog_border_bottom dialog_border_bottom_2"/>
      <div class="dialog_border dialog_border_bottom dialog_border_bottom_3"/>
      <div class="dialog_border dialog_border_bottom dialog_border_bottom_4"/>
      <div class="dialog_border dialog_border_bottom dialog_border_bottom_5"/>
      <div class="dialog_border dialog_border_bottom dialog_border_bottom_6"/>
      <div class="dialog_border dialog_border_bottom dialog_border_bottom_7"/>
      <div class="dialog_border dialog_border_bottom dialog_border_bottom_8"/>
      <div class="dialog_border dialog_border_bottom dialog_border_bottom_9"/>

      <div class="dialog_border dialog_border_left dialog_border_left_1"/>
      <div class="dialog_border dialog_border_left dialog_border_left_2"/>
      <div class="dialog_border dialog_border_left dialog_border_left_3"/>
      <div class="dialog_border dialog_border_left dialog_border_left_4"/>

      <div class="dialog_form_container">
        <div class="dialog_title">{{ $t('dialog.title') }}</div>
        <form
          @submit.prevent="sendForm"
          class="dialog_form"
        >
          <wd-input
            :error="$v.name.$error && 'required'"
            :label="$t('dialog.name')"
            @keyup="$v.name.$touch()"
            v-model="name"
          />
          <wd-input
            :error="$v.phone.$error && 'required'"
            :label="$t('dialog.phone')"
            @keyup="$v.phone.$touch()"
            v-model="phone"
          />
          <wd-input
            :error="emailErrorMess"
            :label="$t('dialog.email')"
            @keyup="$v.email.$touch()"
            v-model="email"
          />
          <wd-textarea :label="$t('dialog.description')" textarea v-model="description"/>
          <wd-file-list v-model="files" v-show="files.length"/>
          <div class="dialog_form_footer">
            <wd-button class="dialog_form_footer_submit" type="submit">
              {{ $t('dialog.submit') }}
            </wd-button>
            <wd-file @input="upload"/>
          </div>
        </form>
      </div>
    </div>

    <thank-you-page @close="thankYouVisible = false" v-if="thankYouVisible"/>
  </transition>
</template>

<script>
  import Logo from '../../logo'
  import { required, email } from 'vuelidate/lib/validators'
  import ThankYouPage from './thank-you-page'

  export default {
    name: 'contacts-dialog',

    components: {
      Logo,
      ThankYouPage
    },

    data() {
      return {
        name: '',
        phone: '',
        email: '',
        description: '',
        files: [],
        thankYouVisible: false
      }
    },

    computed: {
      emailErrorMess() {
        const v = this.$v.email
        if (v.$dirty) {
          if (!v.required) {
            return 'required'
          }
          if (!v.email) {
            return 'email'
          }
        }
        return false
      }
    },

    validations: {
      name: { required },
      phone: { required },
      email: { required, email }
    },

    methods: {
      upload(file) {
        this.files.push(file)
      },

      resetForm() {
        this.name = ''
        this.phone = ''
        this.email = ''
        this.description = ''
        this.files.splice(0, this.files.length)
      },

      async sendForm() {
        this.$v.$touch()
        if (this.$v.$error) {
          return
        }
        // TODO move to vuex
        const formData = new FormData()
        this.files.forEach((file, index) => {
          formData.append(`file[${ index }]`, file)
        })

        for (const field of ['name', 'phone', 'email', 'description']) {
          formData.append(field, this[field])
        }

        const response = await fetch('https://api.wedo.in.ua/api/contact', {
          method: 'POST',
          body: formData
        })

        if (response.status === 200) {
          this.$root.showDialog(false)
          this.thankYouVisible = true
        } else {
          console.error(response)
        }

        this.resetForm()
      }
    }
  }
</script>

<style lang="scss">
  .dialog {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background: rgb(var(--bg));
    overflow: auto;
  }

  .dialog_close {
    position: absolute;
    top: 7.3rem;
    right: 8rem;

    @include mobile {
      top: 2rem;
      right: 2rem;
    }
  }

  .dialog_title {
    font-weight: 300;
    font-size: 3.6rem;
    line-height: 1.33;
    letter-spacing: 0.08em;
    color: $primary;
    margin-bottom: 2.5rem;

    @include mobile {
      font-size: 2.4rem;
      padding: 0 2rem;
    }
  }

  .dialog_form_container {
    max-width: 76.8rem;
    margin: 4.2rem auto;

    @include mobile {
      margin-top: 10rem;
    }
  }

  .dialog_form {
    padding-left: 5rem;

    @include mobile {
      padding: 0 2rem;
    }
  }

  .dialog_form_footer {
    display: flex;

    @include desktop {
      justify-content: space-between;
      max-width: 46.4rem;
    }

    @include mobile {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }

  .dialog_form_footer_submit {
    @include mobile {
      margin-top: 4rem;
    }
  }

  .dialog_border {
    position: absolute;
    background: $secondary-light;

    @include mobile {
      display: none;
    }
  }

  .dialog_border_top {
    top: 4rem;
    height: 1px;
  }

  .dialog_border_right {
    right: 4rem;
    width: 1px;
  }

  .dialog_border_bottom {
    bottom: 4rem;
    height: 1px;
  }

  .dialog_border_left {
    left: 4rem;
    width: 1px;
  }

  .dialog_border_top_1 {
    width: 0.4rem;
    right: 15.6rem;
  }

  .dialog_border_top_2 {
    width: 1.6rem;
    right: 12.1rem;
  }

  .dialog_border_top_3 {
    width: 0.8rem;
    right: 10.5rem;
  }

  .dialog_border_top_4 {
    width: 0.4rem;
    right: 9.3rem;
  }

  .dialog_border_top_5 {
    width: 4.8rem;
    right: 2.5rem;
  }

  .dialog_border_right_1 {
    top: 3.7rem;
    height: 2.4rem;
  }

  .dialog_border_right_2 {
    top: 7.3rem;
    height: 0.4rem;
  }

  .dialog_border_right_3 {
    top: 8.9rem;
    height: 0.8rem;
  }

  .dialog_border_right_4 {
    top: 12.9rem;
    height: 0.8rem;
  }

  .dialog_border_right_5 {
    top: 14.1rem;
    height: 1.6rem;
  }

  .dialog_border_right_6 {
    bottom: 3rem;
    height: 2.4rem;
  }

  .dialog_border_right_7 {
    bottom: 6.7rem;
    height: 0.8rem;
  }

  .dialog_border_right_8 {
    bottom: 9.1rem;
    height: 0.4rem;
  }

  .dialog_border_right_9 {
    bottom: 12.3rem;
    height: 3.2rem;
  }

  .dialog_border_bottom_1 {
    left: 3.6rem;
    width: 1.8rem;
  }

  .dialog_border_bottom_2 {
    left: 7.7rem;
    width: 0.4rem;
  }

  .dialog_border_bottom_3 {
    left: 9.5rem;
    width: 0.8rem;
  }

  .dialog_border_bottom_4 {
    left: 11.1rem;
    width: 0.4rem;
  }

  .dialog_border_bottom_5 {
    right: 15.2rem;
    width: 0.8rem;
  }

  .dialog_border_bottom_6 {
    right: 10.9rem;
    width: 2.4rem;
  }

  .dialog_border_bottom_7 {
    right: 8.9rem;
    width: 0.4rem;
  }

  .dialog_border_bottom_8 {
    right: 5.7rem;
    width: 0.8rem;
  }

  .dialog_border_bottom_9 {
    right: 3.7rem;
    width: 1.6rem;
  }

  .dialog_border_left_1 {
    bottom: 12.3rem;
    height: 1.8rem;
  }

  .dialog_border_left_2 {
    bottom: 7.5rem;
    height: 1.6rem;
  }

  .dialog_border_left_3 {
    bottom: 6.7rem;
    height: 0.4rem;
  }

  .dialog_border_left_4 {
    bottom: 3.1rem;
    height: 2.4rem;
  }

  .dialog_close_border .dialog_border {
    transition: .2s;
  }

  .dialog_close:hover + .dialog_close_border {
    .dialog_border_top_1 {
      right: 11.6rem;
    }

    .dialog_border_top_2 {
      right: 9.7rem;
    }

    .dialog_border_top_3 {
      right: 8.1rem;
    }

    .dialog_border_top_4 {
      right: 6.1rem;
    }

    .dialog_border_top_5 {
      width: 3.2rem;
    }

    .dialog_border_right_1 {
      top: 3.7rem;
      height: 1.6rem;
    }

    .dialog_border_right_2 {
      top: 5.7rem;
    }

    .dialog_border_right_3 {
      top: 6.5rem;
    }

    .dialog_border_right_4 {
      top: 8.9rem;
    }

    .dialog_border_right_5 {
      top: 10.1rem;
    }
  }

  .dialog-transition {
    &-enter-active,
    &-leave-active {
      transition: $transition-duration / 2;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
    }
  }
</style>
