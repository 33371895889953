<template>
  <div>
    <div class="about_title">
      {{ $t('about.view3.title') }}
    </div>

    <div class="about_competencies about_content">
      <div class="about_competence_section">
        <div class="about_competencies_item competence-transition-1">{{ $t('about.view3.competitions.1') }}</div>
        <div class="about_competencies_item competence-transition-2">{{ $t('about.view3.competitions.2') }}</div>
        <div class="about_competencies_item competence-transition-3">{{ $t('about.view3.competitions.3') }}</div>
      </div>
      <div class="about_competence_section">
        <div class="about_competencies_item competence-transition-4">{{ $t('about.view3.competitions.4') }}</div>
        <div class="about_competencies_item competence-transition-5">{{ $t('about.view3.competitions.5') }}</div>
        <div class="about_competencies_item competence-transition-6">{{ $t('about.view3.competitions.6') }}</div>
      </div>
    </div>

    <mobile-arrow
      @click.native="$router.push({ name: 'services' })"
      key="about3"
    />
  </div>
</template>

<script>
  import MobileArrow from '../../mobile-arrow'

  export default {
    name: 'page-3',

    components: {
      MobileArrow
    }
  }
</script>

<style lang="scss">
  .about_competencies {
    display: flex;
    counter-reset: competence;

    @include desktop {
      padding-left: 6.4rem;
    }
  }

  .about_competence_section {
    width: 50%;
  }

  .about_competencies_item {
    color: $primary-light;
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 1.35;
    letter-spacing: 0.08em;
    position: relative;
    padding-left: 3.2rem;
    padding-top: 2.4rem;
    margin-bottom: 6.6rem;

    @include mobile {
      font-size: 1.4rem;
      padding-right: 1rem;
      word-break: break-word;
      height: 7rem;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      counter-increment: competence;
      content: counter(competence);
      font-weight: 900;
      font-size: 7.2rem;
      line-height: 1.35;
      letter-spacing: 0.08em;
      color: $primary-light;
      opacity: 0.1;
      position: absolute;
      top: 0;
      left: 0;

      @include mobile {
        top: -15%;
      }
    }
  }
</style>
