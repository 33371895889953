<template>
  <div class="home">
    <div class="home_brand_name">
      {{ $t('home.brandName') }}
    </div>
    <h1 class="home_brand_name_subtitle">
      {{ $t('home.subtitle') }}
    </h1>
    <div class="home_occupation_list">
      <transition name="home-occupation">
        <div
          :key="index" class="home_occupation_item"
          v-for="(occupation, index) in $t('home.occupations')"
          v-if="animationCounter === index"
        >
          {{ occupation }}
        </div>
      </transition>
    </div>

    <mobile-arrow
      @click.native="$router.push({ name: 'about' })"
      key="home"
    />
  </div>
</template>

<script>
  import MobileArrow from '../mobile-arrow'

  export default {
    name: 'home',

    components: {
      MobileArrow
    },

    data() {
      return {
        intervalTimer: null,
        animationCounter: 0
      }
    },

    mounted() {
      this.intervalTimer = setInterval(() => {
        if (this.animationCounter === 3) {
          this.animationCounter = 0
        } else {
          this.animationCounter += 1
        }
      }, 2000)
    },

    beforeDestroy() {
      clearInterval(this.intervalTimer)
    }
  }
</script>

<style lang="scss">
  .home {
    padding-top: 20rem;
    display: grid;
    grid-template-columns: 19rem auto;
    grid-template-areas: 'title occupations' 'subtitle occupations';

    @include mobile {
      padding-top: 9rem;
      grid-template-columns: auto;
      grid-template-areas: 'title' 'occupations' 'subtitle';
    }
  }

  .home_brand_name {
    font-weight: 900;
    font-size: 4.8rem;
    color: $secondary;
    grid-area: title;
  }

  .home_brand_name_subtitle {
    font-size: 2.4rem;
    color: $secondary-light;
    letter-spacing: 0.08em;
    grid-area: subtitle;

    @include mobile {
      font-weight: 900;
      font-size: 1.4rem;
      margin-left: 16rem;
      width: 13rem;
    }
  }

  .home_occupation_list {
    font-weight: 900;
    font-size: 4.8rem;
    color: rgb(var(--text-contrast));
    position: relative;
    grid-area: occupations;

    @include mobile {
      height: 13rem;
      font-weight: 300;
      font-size: 3.6rem;
      padding-top: 2rem;
    }
  }

  .home_occupation_item {
    white-space: nowrap;
  }

  .home-occupation-enter-active,
  .home-occupation-leave-active {
    transition: transform $transition-duration, opacity $transition-duration/2;
    position: absolute;
  }

  .home-occupation-enter {
    transform: translateY(-100%);
    opacity: 0;

    @include mobile {
      transform: translateY(100%);
    }
  }

  .home-occupation-leave-to {
    transform: translateY(100%);
    opacity: 0;

    @include mobile {
      transform: translateY(-100%);
    }
  }
</style>
