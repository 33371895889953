<template>
  <div class="about">
    <div class="transition_container">
      <transition name="about-transition">
        <component
          :is="'page-' + activeAbout"
          @next-page="$emit('about-next-page', $event)"
        />
      </transition>
    </div>
  </div>
</template>

<script>
  import Page1 from './page-1'
  import Page2 from './page-2'
  import Page3 from './page-3'

  export default {
    name: 'about',
    components: {
      Page1,
      Page2,
      Page3
    },

    props: {
      activeAbout: {
        type: Number,
        default: 1
      }
    }
  }
</script>

<style lang="scss">
  .about {
    @include desktop {
      padding-top: 14rem;
      padding-right: 16rem;
    }
  }

  .about_title {
    color: $secondary;
    font-weight: 300;
    font-size: 3.6rem;
    line-height: 1.5;
    letter-spacing: 0.08em;

    @include mobile {
      font-size: 2.4rem;
      padding-top: 5rem;
    }
  }

  /* transitions */

  .about-transition-enter-active,
  .about-transition-leave-active {
    transition: $transition-duration * 2;
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .about_title {
    .about-transition-enter-active &,
    .about-transition-leave-active & {
      transition: $transition-duration;
    }

    .about-transition-leave-to &,
    .about-transition-enter & {
      transform: translate(-4rem, -4rem);
      opacity: 0;
    }
  }

  .about_content {
    .about-transition-leave-active & {
      transition: $transition-duration;
    }

    .about-transition-leave-to & {
      transform: translate(4rem, 4rem);
      opacity: 0;
    }
  }

  .about_sections,
  .about_text {
    .about-transition-enter-active & {
      transition: $transition-duration;
    }

    .about-transition-enter & {
      transform: translate(-4rem, -4rem);
      opacity: 0;
    }
  }

  @for $i from 1 through 6 {
    .competence-transition-#{$i} {
      .about-transition-enter-active & {
        transition: $transition-duration * 2 / 6;
        transition-delay: ($i - 1) * ($transition-duration * 2 / 6);
      }

      .about-transition-enter & {
        transform: translateX(4rem);
        opacity: 0;
      }
    }
  }

  .mobile_nav_arrow {
    .about-transition-enter-active &,
    .about-transition-leave-active & {
      transition: $transition-duration;
    }

    .about-transition-enter &,
    .about-transition-leave-to & {
      transform: translateY(4rem);
      opacity: 0;
    }
  }
</style>
