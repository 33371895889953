export default {
  router: {
    home: 'Головна',
    about: 'Про нас',
    services: 'Послуги',
    contacts: 'Контакти',
    portfolio: 'Портфоліо'
  },
  app: {
    portfolio: 'Портфоліо'
  },
  dialog: {
    title: 'Почати проект',
    name: 'Ім’я',
    phone: 'Номер',
    email: 'e-mail',
    description: 'Повідомлення',
    submit: 'Відправити',
    close: 'Закрити',
    back: 'Назад'
  },
  orderNowButton: 'Почати',
  uiKit: {
    file: {
      caption: 'Додати файл'
    },
    fileList: {
      label: 'Вкладення'
    },
    input: {
      error: {
        required: 'Це поле обов’язкове для заповнення',
        email: 'E-mail не відповідає формату'
      }
    }
  },
  home: {
    occupations: [
      'веб-сайти',
      'дизайн',
      'просування',
      'боти'
    ],
    brandName: 'WEDO',
    subtitle: 'Ми робимо все, що Вам потрібно'
  },
  about: {
    view1: {
      title: 'Ми створюємо аутсорс-рішення для Вашого бізнесу',
      technology: {
        title: 'Технології',
        list: [
          'Створюємо адаптивний дизайн',
          'Програмуємо на фреймворках Laravel та Yii',
          'Верстаємо та анімуємо за допомогою HTML5 + CSS3',
          'Frontend розроблюємо на Vue.js, React, Angular'
        ]
      },
      clients: {
        title: 'Клієнти',
        description: 'Ми працюємо з амбітними компаніями - створюємо для них нові рішення та покращуємо ті, що вже існують. ' +
          'Ми допоможемо реалізувати Ваш проект саме так, як Ви його бачите'
      }
    },
    view2: {
      title: 'Ми створюємо',
      description: 'Сайти, сейрвіси, системи адміністрування, програми лояльності, боти для Facebook Messenger, Telegram, Viber. ' +
        'Базуючись на аналізі Вашого бізнесу, ми готові здійснювати підтримку продукту протягом всього циклу його життя: від створення бренду до просування'
    },
    view3: {
      title: 'Ключові компетенції',
      competitions: {
        1: 'Боти',
        2: 'Frontend',
        3: 'Backend',
        4: 'Дизайн',
        5: 'Прототипування',
        6: 'UX аналітика'
      }
    }
  },
  services: {
    serviceItem: {
      priceFrom: 'від'
    },
    title: 'Що ми можемо зробити для Вас',
    sections: [{
      caption: 'Веб-розробка',
      items: [{
        title: 'Розробка ботів',
        price: 1000,
        text: 'Розумні та доброзичливі боти для месенджера ' +
          'допоможуть зменшити витрати та автоматизувати Ваші воронки продажів ' +
          'або заощадити час працівників команди підтримки'
      }, {
        title: 'Корпоративні сайти',
        price: 1500,
        text: 'Сайт для бізнесу будь-якого рівня, який допоможе заявити ' +
          'про себе та вирішити Ваші бізнес-задачі'
      }, {
        title: 'Лендінги',
        price: 1000,
        text: 'Цільова сторінка для рекламної кампанії, що допоможе залучити відвідувачів та перетворити їх на покупців'
      }, {
        title: 'Інтернет-магазини',
        price: 5000,
        text: 'Зручний інтернет-магазин із навігацією, зрозумілою для покупців - незамінний для старту digital-продажів'
      }, {
        title: 'Розробка технічних завдань',
        price: 200,
        text: 'Наш експерт перетворить Ваші побажання на чітке та зрозуміле для розробників технічне завдання, ' +
          'створить структуру майбутнього ресурсу та перелік готових завдань, а також надасть консультацію розробникам'
      }]
    }, {
      caption: 'Дизайн',
      items: [{
        title: 'Створення бренду',
        price: 700,
        text: 'Логотип з інструкцією з експлуатації, ' +
          'зображений на різноманітних носіях, допоможе Вашому бізнесу народитись унікальним'
      }, {
        title: 'UI/UX дизайн',
        price: 500,
        text: 'Якісний та зручний UX дизайн, з допомогою якого сайт вирішуватиме Ваші бізнес-задачі'
      }, {
        title: 'Графічний дизайн',
        price: 100,
        text: 'Графічні елементи, ілюстрації, типографічна верстка, візитівки, флаєри, банери - все, що душа забажає!'
      }]
    },

    //
    //   {
    //   caption: 'Маркетинг',
    //   items: [{
    //     title: 'SEO-просування',
    //     price: 1000,
    //     text: 'Ми допоможемо пошуковим системам та Вашому сайту знайти одне одного. ' +
    //       'Збір семантики, аналіз конкурентів, закупівля посилань, SEO-оптимізація сайту та багато іншого'
    //   }, {
    //     title: 'SEO-аудит',
    //     price: 400,
    //     text: 'Наш SEO-спеціаліст проаналізує архітектуру сайту, контент, структуру посилань та допоможе усунути помилки, що уповільнюють Ваш ріст'
    //   }, {
    //     title: 'PPC',
    //     price: 1000,
    //     text: 'Створення стратегії рекламних кампаній, підтримка та консультації'
    //   }, {
    //     title: 'Аудит рекламних кампаній',
    //     price: 400,
    //     text: 'Різнобічний та повний аналіз і перевірка Ваших кампаній в AdWords та Facebook допоможуть Вам заощадити до 60% витрат на рекламу'
    //   }]
    // }


    ]
  },
  contacts: {
    title: 'Дозвольте нам створити найкраще рішення для Вас',
    startProject: 'Почати проект',
    ourPresentation: 'Наша презентація',
    description: [
      'Ми - компанія нової ґенерації. Ми знайшли один одного, щоб разом творити мистецтво веб-розробки.',
      'Пропонуємо до Ваших послуг міцну команду талановитих спеціалістів і переможну стратегію web-розробки та digital-маркетингу.',
      'Аутсорс – і цим все сказано!'
    ],
    phoneUkraine: 'Україна',
    phoneGermany: 'Німеччина',
    thankYou: {
      title: 'Ось і почали!',
      sections: [{
        title: 'Тепер у Вас є менеджер',
        text: 'Закріплений за Вами аккаунт-менеджер зв’яжеться з Вами для уточнення всіх деталей.'
      }, {
        title: 'А у нас є Ви',
        text: 'Ми раді, що Ви до нас звернулися. І докладемо максимум зусиль, щоб Ваш проект уникнув помилок, що допускають інші розробники.'
      }],
      button: 'До зустрічі!'
    }
  },

  seo: {
    home: {
      title: 'WeDo – Розробка сайтів, боти для соцмереж, дизайн',
      metaTags: [{
        name: 'description',
        content: 'WeDo ⇒ Створюємо, підтримуємо веб-проекти. 👩‍💻 Розробка ботів для ' +
          'Facebook Messenger і Telegram. 🤖 Графічний дизайн, UI/UX-дизайн, консалтинг'
      }]
    },

    about: {
      title: 'WeDo – Про нас',
      metaTags: [{
        name: 'description',
        content: 'Ми підходимо до дизайну та розробки, як до мистецтва. 🎭 Вебсайти, сервіси, ' +
          'програми лояльності, особисті кабінети.'
      }]
    },

    services: {
      title: 'WeDo – Послуги',
      metaTags: [{
        name: 'description',
        content: 'У нас можна замовити графічний дизайн, UI/UX-дизайн, дизайн веб-сайту або лендингу. 🎨' +
          'Також ми пропонуємо послуги з SEO-просування, SEO-аудиту, PPC 🌐'
      }]
    },

    contacts: {
      title: 'WeDo – Контакти',
      metaTags: [{
        name: 'description',
        content: 'Контакти компанії WeDo. 📞 Нас можна знайти за номером +380(73) 000-39-01. 📧 Напишіть нам: sales@wedo.in.ua'
      }]
    },

    portfolio: {
      title: 'WeDo – Портфоліо',
      metaTags: [{
        name: 'description',
        content: 'WeDo has experience in graphic design, UI/UX-design, website and landing page design' +
            'as well as chatbot development 🤖 and general web development 🌐'
      }]
    },

    portfolioItem: {
      title: 'WeDo – Портфоліо', // TODO finish this
      metaTags: [{
        name: 'description',
        content: 'WeDo has experience in graphic design, UI/UX-design, website and landing page design' +
            'as well as chatbot development 🤖 and general web development 🌐'
      }]
    }

  }
}
