

export default {
    GET_SERVERS(state, payload) {
        // console.log('GET_SERVERS', 88)
        state.servers = payload;
    },
    GET_PERIOD(state, payload) {
        state.period = payload;
    },
    SET_MONTH(state, payload) {
        state.month = payload;
    },
    GET_SELECTSERVER(state, payload){
        state.selectServer = payload;
    }
}
