<template>
  <div class="services">
    <h1 class="services_title">
      {{ $t('services.title') }}
    </h1>
    <div
      :key="index"
      class="service_section" v-for="(section, index) in $t('services.sections')"
    >
      <div class="service_section_caption">
        {{section.caption}}
      </div>

      <service-item
        :key="index" :price="service.price"
        :text="service.text"
        :title="service.title"
        v-for="(service, index) in section.items"
      />
    </div>

    <mobile-arrow
      @click.native="$router.push({ name: 'contacts' })"
      key="services"
    />
  </div>
</template>

<script>
  import ServiceItem from './service-item'
  import MobileArrow from '../../mobile-arrow'

  export default {
    name: 'services',

    components: {
      ServiceItem,
      MobileArrow
    }
  }
</script>

<style lang="scss">
  .services {
    padding: 14rem 0;

    @include mobile {
      padding: 5rem 0;
      padding-bottom: 0;
    }
  }

  .services_title {
    margin: 2.6rem 0;
    font-weight: 300;
    font-size: 3.6rem;
    line-height: 1.33;
    color: $secondary;
    letter-spacing: 0.08em;

    @include mobile {
      font-size: 2.4rem;
    }
  }

  .service_section {
    position: relative;
    border-bottom: 1px solid rgba($secondary-light, 0.2);
    padding-left: 4.4rem;
    padding-bottom: 0.8rem;
    margin-bottom: 0.8rem;

    @include mobile {
      padding-left: 2rem;
      border-bottom: 1px solid rgba(167, 146, 91, 0.2);
    }
  }

  .service_section_caption {
    position: absolute;
    top: 1.5rem;
    left: 0;
    font-size: 1.4rem;
    line-height: 1;
    color: $primary;
    text-transform: uppercase;
    transform: translateX(-100%) rotate(-90deg);
    transform-origin: top right;

    @include mobile {
      font-size: 1rem;
    }
  }
</style>
