<template>
    <div class="fixed_container">
        <div class="drop_wrapper" :class="{active}">
            <div class="button" @mouseenter="active = false">
                <p>
                    {{selectedValue}}
                </p>
                <img class="open_button" svg-inline src="../../../src/assets/icons/arrow_bottom.svg" alt="">
            </div>
            <div class="dropdown_hovered">
                <div class="items">
                    <div class="item" v-for="(value, key) in options" :key="key"
                         :class="{selected: value === selectedValue}"
                         @click="select(key)">
                            {{value}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TemplateDropdown",
        props: {
            options: [Object, Array],
            value: [String, Number],
            defaultName: String
        },
        data: function() {
            return {
                selectedValue: this.value ? (this.options[this.value] ?? this.value)
                    : this.defaultName ? this.defaultName
                       : null,
                // selectedValue: this.options.isArray && this.value ? this.value
                //     : !this.value && this.defaultName ? this.defaultName
                //     : !this.value && !this.defaultName && this.options.isArray ? this.options[0]
                //     : !this.value && !this.defaultName ? this.options[Object.keys(this.options)[0]]
                //     :this.options[this.value],
                active: false
            }
        },
        // mounted() {
        //     console.log('options', this.options)
        //     if (!this.selectedValue) {
        //         this.selectedValue = this.options[Object.keys(this.options)[0]] ?? this.options[0]
        //         this.$emit ("input", this.selectedValue)
        //         console.log(this.selectedValue)
        //     }
        // },
        methods: {
            select (key) {
                console.log(44, key)
                this.selectedValue = this.options[key]
                if (Array.isArray(this.options)) {
                    console.log(101, this.options, Array.isArray(this.options))
                    this.$emit ("input", this.options[key])
                } else {
                    console.log(102, this.options, Array.isArray(this.options))
                    this.$emit ("input", key)
                }
                this.active = true;
                setTimeout(() => this.active = false, 300);
            }
        }
    }
</script>

<style scoped lang="sass">
//  @import "resources/sass/variables"

.fixed_container
    height: 32px
    overflow: visible
    max-width: 128px
    width: 100%
    &.disabled
        .drop_wrapper
            background: transparent
            pointer-events: none
            .button
                background: #EBEEF5
            p
                color: #A9AEB8
            ::v-deep svg path
                fill: #A9AEB8
    .drop_wrapper
        min-width: 128px
        z-index: 10
        position: relative
        width: 100%
        min-height: 32px
        max-height: 32px
        border-radius: 4px
        background: transparent
        cursor: pointer
        //padding: 5px 16px
        // @include flex-between-column
        display: flex
        flex-direction: column
        justify-content: space-between
        font-weight: 500
        font-size: 14px
        line-height: 160%
        color: #42526E
        box-sizing: border-box
        align-items: flex-start
        .button
            height: 32px
            text-overflow: ellipsis
            width: 100%
            max-width: 100%
            background: #EEEEEE
            border-radius: 4px
            outline: 0
            cursor: pointer
            color: #42526E
            padding: 8px 10px 8px 10px
        .dropdown_hovered
            width: 100%
            opacity: 0
            height: auto
            box-sizing: border-box
            display: flex
            flex-direction: column
            justify-content: space-between
            font-weight: 500
            font-size: 14px
            line-height: 160%
            // overflow: hidden
            margin-top: 8px
            .items
                background: white
                border-radius: 8px
                padding: 5px 0
                width: 100%
                grid-row-gap: 4px
                max-height: 300px
                overflow-y: auto
                overflow-x: hidden
                box-shadow: 0px 4px 8px rgba(9, 30, 66, 0.25)
                &::-webkit-scrollbar
                    display: none
                .item
                    color: #10177B
                    font-weight: 500
                    font-size: 14px
                    line-height: 160%
                    padding: 8px 16px
                    &:hover, &.selected
                        background: #F1F2F5
                        color: #13203D
                .item.selected
                    position: relative
                    border-left-width: 2px
                    border-left-color: #003EC4
                    border-left-style: solid
         
                    
                        
                        
        .button
            // @include flex-center-between
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            width: 100%
            min-width: 100%

        &:hover:not(.active)
            z-index: 12
            max-height: 284px
            .button
                background: #10177B
                color: #EEEEEE
            .button p
                color: #EEEEEE
            ::v-deep .open_button path
                fill: #EEEEEE
            .open_button
                transform: rotate(180deg)
            .dropdown_hovered
                opacity: 1

</style>
