// TODO move all constants to a separate place
export default class ScrollHelper {
    bus;
    history;

    constructor(garbageFrequency, historyLength = 30000) {
        this.bus = [];
        this.history = [];
        this.garbageCollector = setInterval(() => {
            const now = new Date();
            this.bus = this.bus.filter(x => x.expires.getTime() > now.getTime());
            this.history = this.history.filter(x => x.expires.getTime() + historyLength > now.getTime());
        }, garbageFrequency)
    }

    /**
     * @param x  Element
     * @param expires  Time in milliseconds
     */
    push(x, expires) {
        let d = new Date();
        const now = d;
        d.setTime(new Date().getTime() + expires);
        let event = {
            payload: x,
            expires: d,
            timestamp: now
        };
        this.bus.push(event);
        this.history.push(event);
    }

    /**
     * Filter the event bus
     * @param rule  handler
     */
    filter(rule) {
        this.bus = this.bus.filter(rule);
    }

    /**
     * Add prevent if there are events in given direction
     * @param preventTime
     */
    preventRush(preventTime) {
        if (this.count(l => l === true) > 0) {
            this.filter(x => x.payload !== true);
            this.push('prevent', preventTime);
        }
        if (this.count(l => l === false) > 0) {
            this.filter(x => x.payload !== false);
            this.push('prevent', preventTime);
        }
    }

    /**
     * @param rule
     * @returns [] only timestamps of given events in history
     */
    timestamps(rule) {
        return this.history
            .filter(rule)
            .map(x => x.timestamp.getTime())
            .sort();
    }

    /**
     * @param value
     * @returns [] intervals between events in history
     */
    intervals(value) {
        const timestamps = this.timestamps(x => x.payload === value);
        let first = timestamps.slice(0, -1);
        return timestamps.slice(1)
            .map((x, i) => x - first[i])
    }

    /*
     * Can be used to determine if this is a beginning of a touchpad/mouse scroll or not
     * @param value
     * @param elements
     * @returns boolean
     */
    existsMinimalNegativeBump(direction, diff = 100, elements = 15) {
        const intervals = this.intervals(direction);
        // User just began scrolling
        if (elements > intervals.length - 1 && intervals.length > 3)
            elements = intervals.length - 1;
        else if (elements > intervals.length - 1)
            return true;

        // Calculate accelerations and find bumps
        let first = intervals.slice(-elements - 1, -1);
        let accelerations =  intervals.slice(-elements)
            .map((x, i) => x - first[i])
            .filter(x => x <= -diff)
        return accelerations.length > 0;
    }

    /**
     * Use to move up-down
     * @param threshold
     * @param preventTime
     * @param direction
     * @param action
     * @param mobile
     */
    performActionIfAllowed(threshold, preventTime, direction, action, mobile) {
        const now = new Date();
        let overwhelmed = this.history
            .filter(x => x.payload === direction)
            .filter(x => x.expires.getTime() + 3000 > now.getTime())
            .length > 150

        if (this.count(l => l === direction) > threshold
            && (this.existsMinimalNegativeBump(direction) || overwhelmed || mobile)
            && this.count(l => l === 'prevent') === 0) {

            this.clear();
            this.push('prevent', preventTime);
            action();
        }
    }

    /**
     * Removes all garbage
     */
    clear() {
        this.bus = [];
    }

    /**
     * Returns number of elements matching rule
     * @param rule
     */
    count(rule) {
        return this.bus.filter(x => rule(x.payload)).length;
    }
}
