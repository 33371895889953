
export default {
    namespaced: true,
    state: {
        mobileMenuOpened: false,
    },
    mutations: {
        SET_MOBILE_MENU_OPENED(state, payload) {
            state.mobileMenuOpened = payload
        }
    },
    actions: {
    },
    getters: {
    }
}
