import mutations from './mutations'
import actions from './actions'
import getters from './getters'
export default {
    namespaced: true,
    state: {
        servers: null,
        period: null,
        month: null,
        selectServer: "All",
    },
    mutations,
    actions,
    getters
};
