<template>
  <nav class="navigation">
    <transition-group
      @before-enter="beforeEnterTransition"
      appear
      tag="div"
      name="navigation-transition"
    >
      <router-link
        :key="route.name"
        :to="{
        name: route.name,
        params: {
          lang: $route.params.lang
        }
      }"
        class="navigation_item"
        v-for="route in $router.options.routes.filter(x => x.meta.mainPageScroll)"
      >
        <span>{{ $t(`router.${route.name}`) }}</span>
      </router-link>
    </transition-group>
  </nav>
</template>

<script>
  export default {
    name: 'navigation',

    data() {
      return {
        counter: 0
      }
    },

    methods: {
      beforeEnterTransition(el) {
        el.style.transitionDelay = this.counter * 300 + 'ms'
        this.counter++
      }
    }
  }
</script>

<style lang="scss">
  .navigation {
    @include desktop {
      margin-top: 22rem;
      padding-left: 4rem;
    }
  }

  .navigation_item {
    display: block;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 2;
    margin-bottom: 0.8rem;
    text-decoration: none;
    position: relative;
    color: $primary;

    @include desktop {
      padding-left: 4rem;
    }

    span {
      display: block;
      transition: .2s;
      white-space: nowrap;
    }

    &:hover span {
      @include desktop {
        transform: translateX(-0.8rem);
        color: $primary-light;
      }
    }

    &.router-link-exact-active span {
      color: $secondary;

      @include desktop {
        transform: translateX(-1.6rem);
      }
    }

    &:before {
      content: '';
      width: 0.4rem;
      height: 0.4rem;
      border: 1px solid $primary;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: 0.6rem;
      transform: translateY(-50%);
      transition: .2s;

      @include mobile {
        content: none
      }
    }

    &:hover:before {
      width: 0.8rem;
      height: 0.8rem;
      left: 0.4rem;
      border-color: $primary-light;
    }

    &:after {
      content: '';
      width: 0.4rem;
      height: 0.4rem;
      background: $secondary;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0.6rem;
      transition: .2s;
      opacity: 0;

      @include mobile {
        content: none
      }
    }

    &:hover:after {
      opacity: 1;
    }

    &.router-link-exact-active:after {
      width: 0.8rem;
      height: 0.8rem;
      left: 0.4rem;
      opacity: 1;
    }

    &.router-link-exact-active:before {
      opacity: 0;
    }
  }

  .navigation-transition-enter-active {
    transition: $transition-duration;
  }

  .navigation-transition-enter {
    transform: translateX(-2rem);
    opacity: 0;
  }
</style>
