<template>
  <a
    class="wd_link"
    :class="{
      'wd_link_right': align === 'right'
    }"
  >
    <div class="wd_link_border wd_link_border_1"/>
    <div class="wd_link_border wd_link_border_2"/>
    <div class="wd_link_border wd_link_border_3"/>
    <div class="wd_link_border wd_link_border_4"/>
    <slot/>
  </a>
</template>

<script>
  export default {
    name: 'wd-link',

    props: {
      align: {
        type: String,
        default: 'left'
      }
    }
  }
</script>

<style lang="scss">
  .wd_link {
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 2;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: $primary;
    text-decoration: none;
    position: relative;
    cursor: pointer;
    display: inline-block;

    &:hover {
      color: $primary-light;

      .wd_link_border {
        background: $secondary-light;
      }

      .wd_link_border_1 {
        width: 30%;
      }

      .wd_link_border_2 {
        width: 6%;
        left: 50%;
      }

      .wd_link_border_3 {
        width: 8%;
        left: 78%;
      }

      .wd_link_border_4 {
        left: 96%;
        width: 4%;
      }
    }
  }

  .wd_link_border {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 1.8rem;
    transition: .2s;
    background: $primary;
  }

  .wd_link.wd_link_right {
    .wd_link_border {
      left: auto;
      right: 0;
    }

    &:hover {
      .wd_link_border_2 {
        right: 50%;
      }

      .wd_link_border_3 {
        right: 78%;
      }

      .wd_link_border_4 {
        right: 96%;
      }
    }
  }
</style>
