import dayjs from "dayjs";

import axios from '@/api/axios'


export default {
    // REST API
    getServers({dispatch, state, commit}, payload) {
        if (state.servers)
            commit('GET_SERVERS', null)
        commit('SET_MONTH', payload)
        let date = dayjs(payload, "MMMM YYYY" ).format("DD-MM-YYYY")
        let params = date ? `&date=${date}` : ''
        return dispatch('request', {
            promise: axios.get(`/api/servers?token=gQWEkntMsVGWqCLFMzBDJmsKF3GsLv5e${params}`) ,
            commitType: 'GET_SERVERS',
            namespace: 'bot'

        }, { root: true });
        // return dispatch('request', {
        //     promise: ['get', `/api/servers`],
        //     commitType: 'GET_SERVERS',
        //     namespace: 'bot'
        // }, { root: true });

    },
    getPeriod({dispatch}) {
        return dispatch('request', {
            promise: axios.get(`/api/servers/period?token=gQWEkntMsVGWqCLFMzBDJmsKF3GsLv5e`),
            commitType: 'GET_PERIOD',
            namespace: 'bot'
        }, { root: true });
    },

    // getStatsMonthly({dispatch, state}) {
    //     console.log(888)
    //     let month = state.month
    //     let params = month ? `?date=${month}` : ''
    //     return dispatch('request', {
    //         promise: ['get', `/api/stats/monthly${params}`],
    //         commitType: 'GET_STATS_MONTHLY',
    //         namespace: 'wallboard'
    //     }, { root: true });
    // },
};
