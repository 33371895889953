<template>
  <button
    class="wd_button"
    :class="{ invert }"
    v-on="$listeners"
  >
    <div class="wd_button_border wd_button_border_left"/>
    <div class="wd_button_border wd_button_border_right"/>
    <div class="wd_button_border wd_button_border_bottom wd_button_border_bottom_1"/>
    <div class="wd_button_border wd_button_border_bottom wd_button_border_bottom_2"/>
    <div class="wd_button_border wd_button_border_bottom wd_button_border_bottom_3"/>
    <div class="wd_button_border wd_button_border_top wd_button_border_top_1"/>
    <div class="wd_button_border wd_button_border_top wd_button_border_top_2"/>
    <div class="wd_button_border wd_button_border_top wd_button_border_top_3"/>
    <slot/>
  </button>
</template>

<script>
  export default {
    name: 'wd-button',
    props: {
      invert: Boolean
    }
  }
</script>

<style lang="scss">
  .wd_button {
    border: none;
    background: none;
    font-weight: 900;
    font-size: 1.6rem;
    line-height: 2;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: $secondary;
    padding: 0 1.6rem;
    position: relative;
    cursor: pointer;
    transition: color .2s;

    &.invert {
      color: $primary;

      .wd_button_border {
        background: $primary;
      }

      @include desktop {
        &:hover {
          color: #fff;

          .wd_button_border {
            background: #fff;
          }
        }
      }
    }

    &:focus {
      outline: none;
    }

    &:hover {
      color: $secondary-light;

      .wd_button_border {
        background: $secondary-light;
        transform: none;
      }

      .wd_button_border_left {
        top: -0.8rem;
      }

      .wd_button_border_right {
        bottom: -0.8rem;
      }

      .wd_button_border_bottom_1 {
        width: 25%;
        left: calc(75% + 0.8rem);
      }

      .wd_button_border_top_1 {
        width: 25%;
        right: calc(75% + 0.8rem);
      }

      .wd_button_border_bottom_2 {
        left: 23%;
      }

      .wd_button_border_top_2 {
        right: 23%;
      }

      .wd_button_border_bottom_3 {
        left: 0;
      }

      .wd_button_border_top_3 {
        right: 0;
      }
    }
  }

  .wd_button_border {
    position: absolute;
    background: $secondary;
    transition: .2s;
  }

  .wd_button_border_left,
  .wd_button_border_right {
    height: 75%;
    width: 1px;
  }

  .wd_button_border_left {
    top: 0;
    left: 0;
  }

  .wd_button_border_right {
    bottom: 0;
    right: 0;
  }

  .wd_button_border_bottom,
  .wd_button_border_top {
    height: 1px;
  }

  .wd_button_border_bottom {
    bottom: 0;
    left: 0;
  }

  .wd_button_border_top {
    top: 0;
    right: 0;
  }

  .wd_button_border_bottom_1,
  .wd_button_border_top_1 {
    width: 85%;
  }

  .wd_button_border_bottom_2 {
    width: 6%;
    left: -0.4rem;
    transform: scaleX(0);
    transform-origin: left;
  }

  .wd_button_border_top_2 {
    width: 12%;
    right: -0.4rem;
    transform: scaleX(0);
    transform-origin: right;
  }

  .wd_button_border_bottom_3,
  .wd_button_border_top_3 {
    width: 4%;
    transform: scaleX(0);
  }

  .wd_button_border_bottom_3 {
    left: -0.8rem;
    transform-origin: left;
  }

  .wd_button_border_top_3 {
    right: -0.8rem;
    transform-origin: right;
  }
</style>
