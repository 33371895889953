export default {

    commonDateFormat: 'D MMM YYYY HH:mm',


   
    errors: {
        '500' : {
            label: 'Помилка 500',
            color: '#FF0000'
        },
        "SSL" : {
            label: 'SSL помилка (сертифікат)',
            color: '#EB00FF'
        },
        "other" : {
            label: 'Інша помилка',
            color: '#C60077'
        },
        "success" : {
            label: 'Все ок',
            color: '#C4C4C4'
        },
        "noData" : {
            label: 'Немає даних, якщо почали відслідковувати нещодавно',
            color: '#FFFFFF'
        },
        "lossData" : {
            label: 'Втрата даних',
            color: '#37F3FF'
        },
    },



}
