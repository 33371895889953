

import axios from '@/api/axios'
import {isMobile} from "mobile-device-detect";

const DOMAIN = 'https://api.wedo.in.ua/';
const IMG_BASE = 'storage/';

// TODO fix image paths

export default {
    namespaced: true,
    state: {
        portfolioList: [[]],
        direction: 1,
    },
    mutations: {
        SET_PORTFOLIO(state, payload) {
            state.portfolioList = payload
        },
        SET_DIRECTION(state, payload) {
            state.direction = payload
        }
    },
    actions: {
        request({dispatch}, payload) {
            return dispatch('request', Object.assign(payload,{namespace: 'portfolio'}), { root: true })
        },

        getPortfolios({dispatch}, lang) {
            return dispatch('request', {
                promise: axios.get(`/api/portfolios/${lang}`),
                commitType: 'SET_PORTFOLIO'
            });
        },

        // getPortfolioItem({dispatch}, {id}) {
        //     return dispatch('request', {
        //         promise: axios.get(`/api/portfolio/${id}/en`),
        //         commitType: 'SET_PORTFOLIO_ITEM'
        //     });
        // },
    },
    getters: {
        portfolioList: state => {
            let list = state.portfolioList[0].reverse();
            list = !isMobile ? list :
                list.map(p => Object.assign(p, {
                    attachments: p.attachments_mobile
                }));
            return list.map(x => Object.assign(x, {
                preview: DOMAIN + IMG_BASE + x.preview,
                attachments: x.attachments.map(y => Object.assign(y, {
                    src: DOMAIN + y.src
                        .replace('www/storage', 'storage')
                        .replace('portfolios/portfolios', 'portfolios')
                }))
            }))
        }
    }
}
