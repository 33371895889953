import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/sections/home'
import About from '../components/sections/about/index'
import Services from '../components/sections/services/index'
import Contacts from '../components/sections/contacts/index'

import PortfolioCarousel from "@/components/sections/portfolio/PortfolioCarousel";
import PortfolioItemInfo from "@/components/sections/portfolio/PortfolioItemInfo";

Vue.use(VueRouter)

const DOMAIN = 'http://wedo.in.ua'

const createRouter = (i18n) => {
  const locales = i18n.availableLocales.join('|')
  const langPrefix = `/:lang(${locales})?`
  const routes = [{
    path: langPrefix,
    name: 'home',
    component: Home,
    meta: {
      mainPageScroll: true
    }
  }, {
    path: langPrefix + '/about',
    name: 'about',
    component: About,
    meta: {
      mainPageScroll: true
    }
  }, {
    path: langPrefix + '/services',
    name: 'services',
    component: Services,
    meta: {
      mainPageScroll: true
    }
  }, {
    path: langPrefix + '/contacts',
    name: 'contacts',
    component: Contacts,
    meta: {
      mainPageScroll: true
    }
  }, {
    path: langPrefix + '/portfolio/:id',
    name: 'portfolioItem',
    component: PortfolioItemInfo,
    meta: {
      hideNavigation: true
    }
  }, {
    path: langPrefix + '/portfolio',
    name: 'portfolio',
    component: PortfolioCarousel,
    meta: {
      hideNavigation: true
    }
  }]

  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })

  // create canonical link
  const link = document.createElement('link')
  link.setAttribute('rel', 'canonical')
  link.setAttribute('href', DOMAIN)
  document.head.append(link)

  router.beforeResolve((to, from, next) => {
    // set hreflang links for each locale
    for (const locale of i18n.availableLocales) {
      let link = document.querySelector(`link[hreflang=${locale}]`)
      // check if hreflang is created
      if (!link) {
        // create link and set base attrs
        link = document.createElement('link')
        link.setAttribute('rel', 'alternate')
        link.setAttribute('hreflang', locale)
        document.head.append(link)
      }

      // build href by domain + locale + path
      // get path by route name
      // home will have path equal ''
      const routePath = to.name === 'home' ? '' : to.name
      if (locale === 'en') {
        // remove lang param from path. EN is default lang
        link.setAttribute('href', `${DOMAIN}/${routePath}`)
      } else {
        link.setAttribute('href', `${DOMAIN}/${locale}/${routePath}`)
      }
    }

    const lang = i18n.locale
    const routeLocales = i18n.messages[lang].seo[to.name]
    // set lang to html tag
    document.documentElement.lang = lang
    // set title
    document.title = routeLocales.title
    // uniq key which allows to find all meta tags added by router
    const META_MARKER = 'data-router-meta'

    // remove old meta tags on change route
    for (const meta of document.querySelectorAll(`meta[${META_MARKER}]`)) {
      meta.remove()
    }
    // append meta tags for current row
    for (const { name, content } of routeLocales.metaTags) {
      const metaTag = document.createElement('meta')
      metaTag.setAttribute('name', name)
      metaTag.setAttribute('content', content)
      metaTag.setAttribute(META_MARKER, '')
      document.head.append(metaTag)
    }

    next()
  })

  router.beforeEach((to, from, next) => {
    const lang = to.params.lang

    if (lang) {
      i18n.locale = lang
    }
    if (lang === 'en') {
      next({ name: to.name })
    } else {
      next()
    }
  })

  return router
}

export default createRouter
