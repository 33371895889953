<template>
  <div class="theme-switch">
    <div class="celestial-body" @click="$root.darkTheme = false">
      <img src="../assets/icons/sun_off.svg" alt="light theme off"/>
      <img src="../assets/icons/sun_on.svg" class="on" :class="{active: !$root.darkTheme}" alt="light theme on"/>
    </div>
    <wd-switch v-model="$root.darkTheme"/>
    <div class="celestial-body" @click="$root.darkTheme = true">
      <img src="../assets/icons/moon_off.svg" alt="dark theme off"/>
      <img src="../assets/icons/moon_on.svg" class="on" :class="{active: $root.darkTheme}" alt="dark theme on"/>
    </div>
  </div>
</template>

<script>
import WdSwitch from "@/components/ui-kit/wd-switch";

export default {
  name: "theme-switch",
  components: {WdSwitch}
}

</script>

<style scoped lang="scss">

  @import "src/css/vars";

  .theme-switch {
    display: flex;

    > :not(:last-child) {
      margin-right: 0.8rem;
    }

    > :not(:last-child):not(.switch), img {
      height: 2.4rem;
      width: 2.4rem;
    }

    .switch {
      width: 2.4rem;
      height: 1.44rem;
      margin-top: 0.48rem;
    }

    .celestial-body {
      position: relative;
      cursor: pointer;

      .on {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity $transition-duration;
      }

      .active {
        opacity: 1;
      }
    }
  }
</style>
