import VueI18n from 'vue-i18n'
import Vue from 'vue'
import en from './en'
// import ru from './ru'
import de from './de'
import uk from './uk'

Vue.use(VueI18n)

export default new VueI18n({
  locale: 'en',
  messages: {
    en,
    // ru,
    de,
    uk
  }
})
