<template>
    <div class="scroll-table-body" ref="table" :style="{ '--height': height + 'px' }">
        <table>
            <thead>
            <tr>
                <th class="width40">Проєкт</th>
                <th v-for="date in daysInMonth" class="left name">{{ dateFormat(date) }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(server) in serversList" :key="server.name">
                <td class="name"><a class="link" title="Zobacz stronę" :href="'https://' + server.url" target="_blank">{{ server.name }}</a></td>
                <td v-for="date in daysInMonth" class="value">
                    <div class="response">
                        <div class="response_item" :title="item.response_message" v-for="item in sortResponses(server.responses, date)"
                             :style="{ '--color': Object.keys(item).length !== 0 ? getResponseColor(item) : missingData(server.first_test, date)}"></div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import dayjs from "dayjs";
dayjs.locale('uk')


import structures from "../../../src/store/bot/structures";
export default {
    name: "TrackerTable",
    data() {
        return {
            height: null,
            interval: 6,
            startNowDay: this.getStartDayTime(new Date())
        }
    },
    computed: {
        ...mapState('bot', ['month', 'servers']),
        ...mapGetters('bot', ['serversList']),
        daysInMonth() {
            let amountDays = dayjs(this.month, "MMMM YYYY").daysInMonth()
            let arrDays = [];
            while (amountDays) {
                arrDays.push(dayjs(this.month, "MMMM YYYY").date(amountDays).toDate());
                amountDays--;
            }
            return arrDays.reverse();
        },
        dayIntervals() {
            let dayIntervals = []
            for (let i = 0; i < 24/this.interval; ++i) {
                dayIntervals.push(i*this.interval)
            }
            return dayIntervals
        }
    },
    mounted() {
        this.height = this.$refs.table.offsetTop
    },
    methods: {
        // sortServers(){

        // }
        sortResponses(res, date) {

            let dateFilter = res.filter(i => this.dateFormat(i.created_at) === this.dateFormat(date))
            let sortResp = []
            let startTimeDate = this.getStartDayTime(date)
            sortResp = this.dayIntervals.filter(i => this.startNowDay > startTimeDate || (this.startNowDay === startTimeDate &&  i < this.getHour(new Date()))).reduce((a, int) =>
                ({ ...a, [int]: dateFilter.filter(i => {
                        let firstTerm = int ? this.getHour(i.created_at) > int : true
                        let secondTerm = int < this.dayIntervals[this.dayIntervals.length - 1] ? this.getHour(i.created_at) <= int + this.interval : true
                        return firstTerm && secondTerm
                    }).reduce((res, resp, i, arr) => {
                        return arr.length > 1 ? arr.filter(i => dayjs(i.created_at).toDate().getTime() === Math.max(...arr.map(i => dayjs(i.created_at).toDate().getTime())))[0]
                            : !resp ? {} : {...resp}
                    }, {})
                }), {})
            return sortResp
        },
        dateFormat(date) {
            return dayjs(date).format("DD.MM")
        },
        getStartDayTime(date) {
            return dayjs(date).toDate().getTime()
        },
        // getColor(item, all, date) {
        //     let serverFirstRes = all.find(res => dayjs(res.created_at).toDate().getTime() === Math.min(...all.map(item  => {
        //         return dayjs(item.created_at).toDate().getTime()
        //     })))
        //     return typeof item === 'object' ? structures.errors['Error' + item.response_code].color
        //         : typeof item !== 'object' && serverFirstRes
        //         && date.getTime() > dayjs(serverFirstRes.created_at).toDate().getTime()
        //             && date.getTime() < new Date().getTime() && dayjs(serverFirstRes.created_at).toDate().getTime() < new Date().getTime() ? structures.errors.lossData.color
        //             :structures.errors.noData.color
        // },
        getResponseColor(item) {
            if (item.response_type === 'error') {
                return structures.errors[item.response_code] ? structures.errors[item.response_code].color
                    : item.response_message.includes('TLS' || 'SSL' || 'certificate') ? structures.errors.SSL.color
                        : structures.errors.other.color
            } else {
                return structures.errors.success.color
            }
        },
        missingData(serverFirstRes, date) {
            // let serverFirstRes = all.find(res => dayjs(res.created_at).toDate().getTime() === Math.min(...all.map(item  => {
            //     return dayjs(item.created_at).toDate().getTime()
            // })))
            return serverFirstRes
            && date.getTime() > dayjs(serverFirstRes).toDate().getTime()
            && date.getTime() < new Date().getTime() && dayjs(serverFirstRes).toDate().getTime() < new Date().getTime() ? structures.errors.lossData.color
                :structures.errors.noData.color
        },
        getHour(day) {
            let hour = dayjs(day).toDate().getHours()
            let minute = dayjs(day).toDate().getMinutes()
            let seconds = dayjs(day).toDate().getSeconds()
            return minute || seconds ? hour + 1 : hour
        }
    }
}
</script>

<style scoped lang="scss">

.scroll-table-body {
    max-height: calc((100vh - 24px) - var(--height));
    overflow-y: auto;
    overflow-x: auto;
    .value {
        .response {
            width: 100%;
            display: flex;
            .response_item {
                width: 25%;
                min-width: 10px;
                height: 10px;
                border-radius: 2px;
                background: var(--color);
            }
        }
    }
    //width: 100%;
    table {
        pointer-events: auto;
        position: relative;
        border-spacing: 0;
        width: 100%;
        cursor: default;
        font-weight: 400;
        font-size: 12px;
        line-height: 134%;
        color: #666666;
        thead {
            th {
                padding: 12px 0 0;
                color: #999999;
                font-weight: 400;
                &:first-child {
                    text-align: left;
                }
            }
        }
        tbody {
            td {
                padding: 12px 0.5px 7px;
                &.name {
                    min-width: 135px;
                    color: #10177B;
                    text-align: left;
                    width: 155px;
                    padding-right: 26px;
                    .link {
                        color: #10177B;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
</style>
