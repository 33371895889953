<template>
  <transition
    :name="appearAnimationShown ? '' : 'logo-transition'"
    appear
    @after-appear="markShown"
  >
    <div class="logo">
      <RouterLink :to="{name: 'home', params: {lang: $route.params.lang}}">
        <svg fill="none" viewBox="0 0 471 160" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M394.33 0C437.344 0 470.935 31.3486 470.935 80.2036C470.935 129.466 437.344 160 394.33 160C363.44 160 337.289 143.949 325.242 116.348C313.264 143.031 287.514 156.831 256.686 156.831H222.809C222.395 156.831 222.059 156.496 222.059 156.081V121.107H173.264C166.593 121.107 161.186 115.728 161.186 109.093C161.186 102.457 166.593 97.0781 173.264 97.0781H222.059V60.5537L170.486 60.5537L180.321 36.5244L222.059 36.5244V2.67234C222.059 2.25812 222.395 1.92233 222.809 1.92233H256.686C287.752 1.92233 313.661 16.3679 325.517 43.5366C337.683 16.2313 363.673 0 394.33 0Z"/>
          <path
            d="M66.0562 1.92233C65.6398 1.92233 65.267 2.18007 65.1201 2.56947L33.1526 87.2958C33.0712 87.5118 32.7654 87.5114 32.6844 87.2953L0.93692 2.57159C0.790591 2.18108 0.417191 1.92233 0 1.92233H66.0562Z"/>
          <path
            d="M153.174 1.92233C152.757 1.92233 152.384 2.18109 152.237 2.57159L120.49 87.2953C120.409 87.5114 120.103 87.5118 120.022 87.2958L88.0542 2.56947C87.9073 2.18007 87.5344 1.92233 87.1181 1.92233H153.174Z"/>
          <path
            d="M44.949 156.045C44.7963 156.423 44.4292 156.671 44.0212 156.671H109.151C108.744 156.671 108.378 156.424 108.224 156.047L76.7085 78.5419C76.6239 78.3338 76.329 78.3342 76.2449 78.5425L44.949 156.045Z"/>
        </svg>
      </RouterLink>
    </div>
  </transition>
</template>

<script>
  export default {
    name: 'logo',
    data() {
      return {
        appearAnimationShown: localStorage.getItem('appearAnimationShown') === 'true'
      }
    },

    methods: {
      markShown() {
        localStorage.setItem('appearAnimationShown', 'true')
      }
    }
  }
</script>

<style lang="scss">
  .logo {
    position: absolute;
    top: 2rem;
    left: 4rem;
    width: 8rem;
    z-index: 4;

    @include mobile {
      left: 1rem;
    }

    svg {
      width: 100%;
    }

    path {
      fill: rgb(var(--text-contrast));
    }
  }

  .logo-transition-enter-active {
    transition: $transition-duration;
    transition-delay: $transition-duration * 3;
  }

  .logo-transition-enter {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(3);
  }
</style>
