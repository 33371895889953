<template>
  <div class="wd_input_container">
    <label
      :class="{
        'is_focus': focus,
        'has_value': value !== '',
        'is_error': error
      }"
      class="wd_input"
    >
      <div class="wd_input_border wd_input_border_left wd_input_border_left_1"/>
      <div class="wd_input_border wd_input_border_left wd_input_border_left_2"/>

      <div class="wd_input_border wd_input_border_right wd_input_border_right_1"/>
      <div class="wd_input_border wd_input_border_right wd_input_border_right_2"/>
      <div class="wd_input_border wd_input_border_right wd_input_border_right_3"/>

      <div class="wd_input_border wd_input_border_bottom wd_input_border_bottom_1"/>
      <div class="wd_input_border wd_input_border_bottom wd_input_border_bottom_2"/>
      <div class="wd_input_border wd_input_border_bottom wd_input_border_bottom_3"/>
      <div class="wd_input_border wd_input_border_bottom wd_input_border_bottom_4"/>
      <div class="wd_input_border wd_input_border_bottom wd_input_border_bottom_5"/>
      <input
        :value="value"
        @blur="focus = false"
        @focus="focus = true"
        @input="$emit('input', $event.target.value)"
        @keyup="$emit('keyup', $event)"
        type="text"
      >
      <span>
        {{label}}
      </span>
    </label>

    <div class="wd_input_error">
      <transition name="error-transition">
        <div v-if="error">
          {{ $t('uiKit.input.error.' + error) }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'wd-input',
    props: {
      label: {
        type: String,
        required: true
      },
      value: String,
      error: [String, Boolean]
    },

    data() {
      return {
        focus: false
      }
    }
  }
</script>

<style lang="scss">
  $side-padding: 1.6rem;
  $label-width: 19.2rem;
  $input-width: 24rem;

  .wd_input_container {
    margin-bottom: 2.4rem;

    @include desktop {
      display: flex;
      align-items: center;
    }
  }

  .wd_input {
    display: flex;
    padding: 0.8rem $side-padding;
    position: relative;
    cursor: pointer;

    @include desktop {
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;
    }

    @include mobile {
      flex-direction: column-reverse;
    }
  }

  .wd_input span {
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 2;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: $primary;
    @include desktop {
      width: $label-width;
    }
  }

  .wd_input input {
    background: none;
    border: none;
    color: $secondary;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.7;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    width: $input-width;

    &:focus {
      color: $secondary-light;
      outline: none;

      & + span {
        color: $primary-light;
      }
    }
  }

  .wd_input_error {
    padding: 0 0.8rem;
    color: $error;
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.45;
    letter-spacing: 0.04em;

    @include desktop {
      width: 20rem;
    }

    @include mobile {
      margin-top: 0.5rem;
    }
  }

  .wd_input_border {
    background: $primary;
    position: absolute;
    transition: .2s;
  }

  .wd_input_border_left {
    top: 0;
    left: 0;
    width: 1px;
    height: 40%;
  }

  .wd_input_border_right {
    bottom: 0;
    right: 0;
    width: 1px;
    height: 40%;
  }

  .wd_input_border_bottom {
    left: $side-padding;
    bottom: 0;
    width: 20%;
    height: 1px;
  }

  @include desktop {
    .wd_input:hover {
      .wd_input_border {
        background: $primary-light;
      }

      .wd_input_border_left_1 {
        top: 40%;
        height: 10%;
      }

      .wd_input_border_left_2 {
        top: 60%;
        height: 40%;
      }

      .wd_input_border_right_1 {
        bottom: 15%;
        height: 20%;
      }

      .wd_input_border_right_2 {
        bottom: 45%;
        height: 10%;
      }

      .wd_input_border_right_3 {
        bottom: 60%;
        height: 40%;
      }

      .wd_input_border_bottom_1 {
        left: $label-width + $side-padding;
        width: $input-width * 0.2;
      }

      .wd_input_border_bottom_2 {
        left: $label-width + $side-padding + $input-width * 0.3;
        width: $input-width * 0.06;
      }

      .wd_input_border_bottom_3 {
        left: $label-width + $side-padding + $input-width * 0.39;
        width: $input-width * 0.1;
      }

      .wd_input_border_bottom_4 {
        left: $label-width + $side-padding + $input-width * 0.72;
        width: $input-width * 0.01;
      }

      .wd_input_border_bottom_5 {
        left: $label-width + $side-padding + $input-width * 0.89;
        width: $input-width * 0.03;
      }
    }
  }

  .wd_input.is_focus {
    .wd_input_border {
      background: $primary-light;
    }

    .wd_input_border_left_1 {
      top: 0;
      height: 80%;
    }

    .wd_input_border_left_2 {
      top: 90%;
      height: 10%;
    }

    .wd_input_border_right_1 {
      bottom: -5%;
      height: 40%;
    }

    .wd_input_border_right_2 {
      bottom: 55%;
      height: 20%;
    }

    .wd_input_border_right_3 {
      bottom: 90%;
      height: 10%;
    }

    .wd_input_border_bottom_1 {
      left: $label-width + $side-padding;
      width: $input-width * 0.4;

      @include mobile {
        left: $side-padding;
        width: 30%;
      }
    }

    .wd_input_border_bottom_2 {
      left: $label-width + $side-padding + $input-width * 0.46;
      width: $input-width * 0.03;

      @include mobile {
        left: 46%;
        width: 3%;
      }
    }

    .wd_input_border_bottom_3 {
      left: $label-width + $side-padding + $input-width * 0.69;
      width: $input-width * 0.2;

      @include mobile {
        left: 69%;
        width: 2%;
      }
    }

    .wd_input_border_bottom_4 {
      left: $label-width + $side-padding + $input-width * 0.91;
      width: $input-width * 0.05;

      @include mobile {
        left: 61%;
        width: 5%;
      }
    }

    .wd_input_border_bottom_5 {
      left: $label-width + $side-padding + $input-width * 0.98;
      width: $input-width * 0.1;

      @include mobile {
        left: 98%;
        width: 4%;
      }
    }
  }

  .wd_input.is_error {
    .wd_input_border_left_1 {
      top: 0;
      height: 40%;
    }

    .wd_input_border_right_1 {
      bottom: 0;
      height: 40%;
    }

    .wd_input_border_bottom_1 {
      left: $side-padding;
      width: $label-width * 0.25;

      @include mobile {
        background: $error;
        width: 3%;
      }
    }

    .wd_input_border_bottom_2 {
      background: $error;
      left: $label-width + $side-padding;
      width: $input-width * 0.01;

      @include mobile {
        left: 13%;
        width: 15%;
      }
    }

    .wd_input_border_bottom_3 {
      background: $error;
      left: $label-width + $side-padding + $input-width * 0.04;
      width: $input-width * 0.1;

      @include mobile {
        left: 36%;
        width: 4%;
      }
    }

    .wd_input_border_bottom_4 {
      background: $error;
      left: $label-width + $side-padding + $input-width * 0.2;
      width: $input-width * 0.05;

      @include mobile {
        left: 43%;
        width: 8%;
      }
    }

    .wd_input_border_bottom_5 {
      background: $error;
      left: $label-width + $side-padding + $input-width * 0.38;
      width: $input-width * 0.2;

      @include mobile {
        left: 66%;
        width: 20%;
      }
    }

    @include desktop {
      &:hover {
        .wd_input_border_bottom_1 {
          background: $error;
          left: $label-width + $side-padding;
          width: $input-width * 0.25;
        }

        .wd_input_border_bottom_2 {
          left: $label-width + $side-padding + $input-width * 0.35;
          width: $input-width * 0.06;
        }

        .wd_input_border_bottom_3 {
          left: $label-width + $side-padding + $input-width * 0.44;
          width: $input-width * 0.1;
        }

        .wd_input_border_bottom_4 {
          left: $label-width + $side-padding + $input-width * 0.77;
          width: $input-width * 0.01;
        }

        .wd_input_border_bottom_5 {
          left: $label-width + $side-padding + $input-width * 0.84;
          width: $input-width * 0.03;
        }
      }
    }
  }

  .wd_input.has_value:not(.is_focus):not(:hover):not(.is_error) {
    .wd_input_border_bottom_2 {
      @include desktop {
        left: $label-width + $side-padding + $input-width * 0.46;
        width: $input-width * 0.4;
      }
    }
  }

  .error-transition-enter-active,
  .error-transition-leave-active {
    transition: .2s;
  }

  .error-transition-leave-to,
  .error-transition-enter {
    transform: translateX(-0.5rem);
    opacity: 0;
  }
</style>
