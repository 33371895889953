<template>
    <div class="bot_header">
        <Dropdown v-if="monthList.length > 0" class="full" v-model="selectMonth"
                          :options="monthList"/>
        <Dropdown :options="uniqueServersList" v-model="selectedServer"/>
        <div class="errors_wrapper">
            <div class="error" v-for="option in errorsList">
                <div class="error_mark" :class="{ border: option.color === '#FFFFFF' }" :style="`--color: ${option.color}`"></div>
                <div class="error_title"> {{option.label}}</div>
            </div>
        </div>

    </div>
</template>

<script>
import structures from "../../../src/store/bot/structures";
import Dropdown from "../ui-kit/TemplateDropdown.vue"
import {mapActions, mapGetters, mapState, mapMutations} from "vuex";

    export default {
        name: "TrackerHead",
        components: {Dropdown},
        computed: {
            ...mapState('bot', ['month', 'servers', 'selectServer']),
            ...mapGetters('bot', ['monthList']),
            selectMonth: {
                get() {
                    return this.month
                },
                set(val){
                    console.log('watchMonth',val)
                    this.getServers(val)
                }
            },

            errorsList() {
                return structures.errors
            },
            uniqueServersList() {

                if(this.servers === null){
                    return []
                }else{

                    return this.servers.reduce((uniqueServers, server) => {
                        if(!uniqueServers.includes(server.server)){
                            uniqueServers.push(server.server)
                        }
                        return uniqueServers
                    },['All'])


                }

            },
            selectedServer: {
                get(){
                    return this.selectServer
                },
                set(val){
                    this.GET_SELECTSERVER(val)
                }


            }

        },
        beforeMount() {
            const date = new Date();
            this.getServers(`${date.toLocaleString('uk-UA', { month: 'long' }).substr(0, 3)} ${date.getFullYear()}`)
        },
        methods: {
            ...mapActions('bot', ['getServers']),
            ...mapMutations('bot', ['GET_SELECTSERVER'])


        },

    }
</script>
<style scoped lang="scss">
    // @import "../../../../sass/variables";
    .bot_header {
        position: sticky;
        top: 0;
        align-items: center;
        grid-gap:24px;
        //justify-content: space-between;
        // @include flex-row;
        display: flex;
        flex-direction: row;
        width: 100%;
        z-index: 1;
        //&>* {
        //   margin-top: 8px;
        //}
        .errors_wrapper {
            // @include flex-row;
            display: flex;
            flex-direction: row;
            min-width: 685px;
            margin: 1px 4px;

            .error {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                &:not(:last-child) {
                    margin-right: 8px;
                }

                .error_title {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 134%;
                    color: #6B778C;
                }

                .error_mark {
                    background-color: var(--color);
                    margin: 0 0.2em 0 0;
                    min-width: 16px;
                    min-height: 16px;
                    width: 16px;
                    height: 16px;
                    -webkit-appearance: none;
                    -webkit-border-radius: 4px;
                    border-radius: 4px;
                    &.border {
                        border: 1px solid #6B778C;
                    }
                }
            }
        }
    }
</style>
