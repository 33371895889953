<template>
  <!-- attribute id is important for prerender -->
  <div @wheel="changePage" @touchmove="touchChangePage" :style="gridStyle" class="container" id="app" :class="{dark: $root.darkTheme}">
    <div
      :class="[
        {
          opened: mobileMenuOpened,
        },
        'active_menu_' + $route.name,
      ]"
      class="mobile_menu"
      ref="mobile_menu"
    >


      <!-- NAVIGATION FOR PORTFOLIO ONLY -->
      <template v-if="showPortfolioNavigationMobile">
        <PortfolioCloseControl/>
        <PortfolioNavigationControl/>
        <PortfolioBackControl/>
      </template>


      <menu-icon
        :opened="mobileMenuOpened"
        @click.native="mobileMenuOpened = !mobileMenuOpened"
      />
      <logo/>

      <!---->
      <div class="navigation-container">
        <transition-group name="hide-nav">
          <navigation key="navigation" v-show="showMobileNavigation"/>

          <div class="mobile_menu_order_button" :key="'order-button'" v-show="showNavigation">
            <wd-button
                @click="$root.showDialog(true)"
            >
              {{ $t('contacts.startProject') }}
            </wd-button>
          </div>

          <div :key="'portfolio-link'" v-show="showNavigation" class="portfolio_link">
            <transition appear name="portfolio-link-transition">
              <div class="portfolio_link_inner">
                <wd-link @click.native="toPortfolioPage">{{
                    $t("app.portfolio")
                  }}</wd-link>
              </div>
            </transition>
          </div>
        </transition-group>

        <transition-group name="hide-nav-reverse">

          <div class="locales" :key="'locales'" v-show="showLocales">

            <transition appear name="switch-transition">
              <theme-switch :key="-1"/>
            </transition>

            <transition-group
                @before-enter="beforeEnterLocaleTransition"
                appear
                name="locales-transition"
            >
              <router-link
                  :class="{
                active: $i18n.locale === lang
              }"
                  :key="lang"
                  :to="{ params: { lang } }"
                  class="locale_item"
                  v-for="lang in $i18n.availableLocales"
              >
                {{ lang }}
              </router-link>
            </transition-group>
          </div>

          <div class="socials" :key="'socials'" v-show="showNavigation">
            <transition-group
                @before-enter="beforeEnterSocialsTransition"
                appear
                name="socials-transition"
            >
              <a v-for="(link, name) in links" :key="name" class="social_item" :href="link" target="_blank">
                <img :src="require(`./assets/icons/${name}.svg`)" :alt="name">
              </a>
            </transition-group>
          </div>
        </transition-group>
      </div>

    </div>
    <div class="content" ref="content" :class="{wide: noOverflow}">
      <div class="transition_container">
        <transition
          @after-leave="setTopToRouterView"
          @before-enter="setTopToRouterView"
          name="router-transition"
        >
          <router-view
            :active-about="activeAbout"
            @about-next-page="aboutNextPage"
          />
        </transition>
      </div>

      <transition name="mouse-transition">
        <mouse-icon @click.native="nextPage" v-show="!isLastPageIndex && showNavigation" />
      </transition>
    </div>

    <!---->
    <grid-loader />

    <contacts-dialog />
  </div>
</template>

<script>
  import Navigation from './components/navigation'
  import Logo from './components/logo'
  import MouseIcon from './components/mouse-icon'
  import GridLoader from './components/grid-loader'
  import ContactsDialog from './components/sections/contacts/dialog'
  import MobileArrow from './components/mobile-arrow'
  import MenuIcon from './components/menu-icon'
  import ThemeSwitch from "@/components/theme-switch";
  import ScrollHelper from "@/helpers/ScrollHelper";

  import {isMobile} from "mobile-device-detect";
  import PortfolioCloseControl from "@/components/sections/portfolio/Controls/PortfolioCloseControl";
  import PortfolioNavigationControl from "@/components/sections/portfolio/Controls/PortfolioNavigationControl";
  import PortfolioBackControl from "@/components/sections/portfolio/Controls/PortfolioBackControl";


// TODO break this component down into smaller ones

export default {
  name: "app",

  components: {
      PortfolioBackControl,
      PortfolioNavigationControl,
      PortfolioCloseControl,
    ThemeSwitch,
    Navigation,
    Logo,
    MouseIcon,
    GridLoader,
    ContactsDialog,
    MobileArrow,
    MenuIcon
  },

  data() {
    return {
      lastDirection: false,
      activeAbout: 1,
      counter: 0,
      counterSocials: 0,
      noOverflow: false,
      links: {
        github: "https://github.com/WeDo-Outsourcing/",
        instagram: "https://www.instagram.com/wedo.in.ua/",
        facebook: "https://www.facebook.com/wedo.in.ua",
        // linkedin: "https://www.linkedin.com/company/wedo-ua",
        // behance: "https://www.behance.net/wedoinua",
      },
      scrollBus: new ScrollHelper(50),
      lastY: 0,
    }
  },

  computed: {
    mobileMenuOpened: {
        get() {
          return this.$store.state.ui.mobileMenuOpened;
        },
        set(v) {
          this.$store.commit('ui/SET_MOBILE_MENU_OPENED', v);
        }
    },

    gridStyle() {
      return {
        "grid-template-columns": this.portfolio() ? "20rem 1fr" : "0fr 1fr ",
      };
    },

    showNavigation() {
      return !this.$route.meta.hideNavigation || isMobile;
    },

    showLocales() {
      return (!this.$route.meta.hideNavigation && !isMobile) || this.mobileMenuOpened
    },

    showPortfolioNavigationMobile() {
      return isMobile && !this.mobileMenuOpened && this.$route.name === 'portfolioItem'
    },

    showMobileNavigation() {
      return !this.$route.meta.hideNavigation || this.mobileMenuOpened;
    },

    routes() {
      return this.$router.options.routes.filter(r => r.meta.mainPageScroll);
    },

    pageIndex() {
      return this.routes.findIndex((r) => r.name === this.$route.name);
    },

    isFirstPageIndex() {
      return this.pageIndex === 0;
    },

    isLastPageIndex() {
      return this.pageIndex === this.routes.length - 1;
    },

    isAboutPage() {
      return this.$route.name === "about";
    },
  },

  watch: {
    $route(to, from) {
      // TODO optimize this
      // this.activeAbout = 1
      if (to.params.lang !== from.params.lang)
        this.refreshPortfolio();

      if (to.name === 'portfolio' || to.name === 'portfolioItem') {
        this.noOverflow = true;
      }
      if ((from.name === 'portfolio' || from.name === 'portfolioItem')
          && to.name !== 'portfolio' && to.name !== 'portfolioItem')
        setTimeout(() => this.noOverflow = false, 1700);


      this.mobileMenuOpened = false;
    }
  },

  methods: {
    portfolio() {
      return (
          this.$route.name !== "portfolio" && this.$route.name !== "portfolioItem"
      );
    },

    toPortfolioPage() {
      this.pushPage("portfolio");
      this.mobileMenuOpened = false;
    },

    setTopToRouterView(el) {
      // el.style.top = this.$refs.content.scrollTop + 'px'
    },

    touchChangePage(event) {
      let currentY = event.touches[0].clientY;
      let deltaY = this.lastY - currentY;
      this.lastY = currentY;

      if(this.scrollBus.count(l => l === 'prevent') > 0 && event.cancelable)
        event.preventDefault();

      let offsetHeight = window.innerHeight - this.$refs.mobile_menu.offsetHeight + 10;

      this.changePage({deltaY}, window.scrollY, offsetHeight, true);
    },

    changePage(event, customScrollTop = null, customOffsetHeight = null, threshold = 2, mobile = false) {
      if(!this.$route.meta.mainPageScroll)
        return;

      let deltaY = event.deltaY;
      let { scrollHeight, scrollTop, offsetHeight} = this.$refs.content
      scrollTop = customScrollTop ? customScrollTop: scrollTop;
      offsetHeight = customOffsetHeight ? customOffsetHeight : offsetHeight;


      const isDown = deltaY > 0;
      const isStart = scrollTop - 5 < 0; // -5 because of browser rounding issues
      const isEnd = scrollTop + offsetHeight + 5 >= scrollHeight; // +5 because of browser rounding issues

      // We measure the amount of events in the recent past and if it exceeds a given threshold, we change the element
      const mark = 100; // ms
      let preventTime = 1000; // ms

      if (mobile) {
        preventTime = 1200;
      }

      // Clear queue if there is a rush
      if ((scrollTop - 150 < 0 && scrollTop - 50 > 0)
          || (scrollTop + offsetHeight + 150 >= scrollHeight && scrollTop + offsetHeight + 50 <= scrollHeight) ) {
        if (!mobile)
          this.scrollBus.preventRush(1000);
      }

      // Add events
      if ((isStart && !isDown) || (isEnd && isDown) || mobile) {
        this.scrollBus.push(isDown, mark);
      }

      // Down scrolls
      if (isEnd) {
        this.scrollBus.performActionIfAllowed(threshold, preventTime, true, () => this.nextPage(), mobile)
      }

      if (isStart) {
        this.scrollBus.performActionIfAllowed(threshold, preventTime, false, () => this.prevPage(), mobile)
      }

    },


    nextPage() {
      if (!this.isLastPageIndex) {
        if (this.isAboutPage && this.activeAbout !== 3) {
          this.activeAbout++
        } else {
          const nextPageName = this.routes[this.pageIndex + 1].name
          // TODO undefined error in console
          if (nextPageName === 'about')
            this.activeAbout = 1;

          // window.scroll(0, -10000);
          this.pushPage(nextPageName);
        }
      }
    },

    prevPage() {
      if (!this.isFirstPageIndex) {
        if (this.isAboutPage && this.activeAbout !== 1) {
          this.activeAbout--
        } else {
          const prevPageName = this.routes[this.pageIndex - 1].name
          // TODO undefined error in console
          this.pushPage(prevPageName);

          if (prevPageName === 'about')
            this.activeAbout = 3;
        }
      }
    },

    pushPage(page) {
      let data = {
        name: page,
        params: {
          lang: this.$route.params.lang
        }
      };
      return this.$router.push(data);
    },

    beforeEnterLocaleTransition(el) {
      el.style.transitionDelay = this.counter * 300 + "ms";
      this.counter++;
    },

    beforeEnterSocialsTransition(el) {
      el.style.transitionDelay = this.counterSocials * 300 + 'ms'
      this.counterSocials++
    },

    aboutNextPage(page) {
      this.activeAbout = page
    },

    refreshPortfolio() {
      this.$store.dispatch('portfolio/getPortfolios', this.$i18n.locale);
    }
  },
  mounted() {
    if (this.$route.name === 'portfolio' || this.$route.name === 'portfolioItem')
      this.noOverflow = true;
    this.refreshPortfolio();
  },
}
</script>

<style lang="scss">

  @import "src/css/vars";

  .container {
    min-height: 100vh;
    overflow-x: hidden;

    @include desktop {
      height: 100vh;
      display: grid;
      position: relative;
      //grid-template-columns: 20rem 1fr;
    }

    @include mobile {
      padding-top: 5rem;
    }

    &.dark {
      --text-contrast: 255, 255, 255;
    }
  }

  .content {

    &.wide {
      overflow: visible;
    }

    @include desktop {
      overflow-x: hidden;
      overflow-y: auto;
      position: absolute;
      left: 20rem;
      bottom: 0;
      top: 0;
      width: calc(100vw - 20rem);
    }

    @include mobile {
      padding-left: 2rem;
      padding-right: 2rem;
      min-height: calc(100vh - 5rem);
    }

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
      background: none;
      width: 0;
    }
  }

  .socials {
    z-index: 2;
    position: absolute;
    bottom: 4.1rem;
    right: 3.6rem;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 2;
    letter-spacing: 0.16rem;
    text-transform: uppercase;
    transform-origin: left bottom;

    span {
      display: flex;
      flex-direction: column-reverse;
    }

    img {
      width: 2.4rem;
      height: 2.4rem;
    }

    > :not(:last-child) {
      margin-bottom: 0.8rem;
    }

    @include mobile {
      bottom: 3rem;
      right: 0 !important;
      width: 100%;
      display: flex;
      justify-content: center;

      span {
        flex-direction: row-reverse;
      }

      a:not(:last-child) {
        margin-bottom: 0;
        margin-left: 1.6rem;
      }
    }
  }

  .social_item {
    //transition: .2s;
    cursor: pointer;
    text-decoration: none;
    display: block;
  }

  .portfolio_link {
    position: absolute;
    bottom: 4rem;
    left: 4rem;
    text-transform: uppercase;
    transition: $transition-duration;
    align-self: flex-end;

    @include mobile {
      width: 100%;
      bottom: 3rem;
      left: 1rem;
    }
  }

  .locales {
    position: absolute;
    z-index: 2;
    top: 2rem;
    right: 4rem;
    text-align: right;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 2;
    text-transform: uppercase;
    transition: color .2s;

    display: flex;

    > span {
      width: 4rem;
    }

    @include mobile {
      top: 5.5rem;
      right: 1.5rem;
    }
  }

  .locale_item {
    text-decoration: none;
    display: block;
    cursor: pointer;
    color: $primary;

    &:hover {
      color: $primary-light;
    }

    &.active {
      color: $secondary;
    }
  }

  .mobile_menu_order_button {
    display: none;
    position: absolute;
    top: 100%;
    left: 3rem;
    opacity: 0;
    transition: $transition-duration;
  }

  @include mobile {
    .mobile_menu {
      z-index: 2;
    }

    .navigation > div {
      transition: $transition-duration;
    }
    .navigation {
      .active_menu_home:not(.opened) & {
        > div {
          transform: translateY(1rem);
        }
      }

      .active_menu_about:not(.opened) & {
        > div {
          transform: translateY(-1.3rem);
        }
      }

      .active_menu_services:not(.opened) & {
        > div {
          transform: translateY(-3.7rem);
        }
      }

      .active_menu_contacts:not(.opened) & {
        > div {
          transform: translateY(-6rem);
        }
      }
    }

    .mobile_menu {
      position: fixed;
      top: 0;
      left: 0;
      height: 5rem;
      width: 100vw;
      text-align: center;
      background: rgb(var(--bg));
      z-index: 4;
      transition: all $transition-duration;

      .navigation {
        height: 5rem;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: $transition-duration;
        overflow: hidden;
        pointer-events: none;

        &:after,
        &:before {
          content: '';
          position: absolute;
          left: 0;
          width: 100%;
          height: 2rem;
          pointer-events: none;
          z-index: 2;
          transition: $transition-duration;
        }

        $transparent: rgba(var(--bg), 0.001); // hack that fixes incorrect display of gradients in mobile safari

        &:before {
          background: linear-gradient(to top, $transparent, rgb(var(--bg)));
          top: 0;
        }

        &:after {
          background: linear-gradient(to bottom, $transparent, rgb(var(--bg)));
          bottom: 0;
        }
      }

      .navigation_item {
        transition: $transition-duration;
        transition-delay: 0s !important;
        margin-bottom: 0;
      }

      .mobile_menu_order_button {
        display: block;
        left: 0;
        width: 100%;
      }

      .logo {
        left: -100%;
        opacity: 0;
        transition: $transition-duration;
      }

      .portfolio_link_inner {
        left: -100%;
        opacity: 0;
        transition: $transition-duration;
      }

      .socials {
        right: -100%;
        opacity: 0;
        transition: $transition-duration;
      }

      .theme-switch {
        opacity: 0;
        top: -100rem;
        transition: $transition-duration;
        position: relative;
        @include mobile {
          top: -4rem !important;
        }
      }

      .locale_item {
        height: 2.4rem;
        transition: $transition-duration;
        transition-delay: 0s !important;

        &:not(.active) {
          height: 0;
          transform: scaleY(0);
        }
      }

      &:not(.opened) {
        .navigation-container {
          pointer-events: none;
        }
      }

      &.opened {
        height: 100%;
        z-index: 5;

        .locale_item:not(.active) {
          height: 2.4rem;
          transform: scaleY(1);
        }

        .mobile_menu_order_button {
          opacity: 1;
          top: 80%;
          transition-delay: $transition-duration * 0.75;
        }

        .logo {
          left: 1rem;
          opacity: 1;
          transition-delay: $transition-duration * 0.75;
        }

        .portfolio_link {
          bottom: 14rem;
          width: 100%;
          left: 0;
          opacity: 1;
          transition-delay: $transition-duration * 0.75;
        }

        .portfolio_link_inner {
          opacity: 1;
        }

        .socials {
          right: 1rem;
          opacity: 1;
          transition-delay: $transition-duration * 0.75;
        }

        .theme-switch {
          opacity: 1;
          top: 0;
        }

        .navigation {
          height: 100%;
          pointer-events: all;
          transform: translateX(-50%) translateY(15rem);

          &:after,
          &:before {
            opacity: 0;
          }
        }

        .navigation_item {
          margin-bottom: 2.4rem;
        }
      }
    }
  }

  .portfolio-transition-enter-active {
    transition: $transition-duration;
  }

  .portfolio-transition-enter {
    transform: translateY(2rem);
    opacity: 0;
  }

  .portfolio-link-transition-enter-active {
    transition: $transition-duration;
  }

  .portfolio-link-transition-enter {
    transform: translateX(-2rem) !important;
    opacity: 0 !important;
  }

  .locales-transition-enter-active, .socials-transition-enter-active, .switch-transition-enter-active {
    transition: $transition-duration;
  }

  .locales-transition-enter, .socials-transition-enter {
    transform: translateX(2rem);
    opacity: 0;
  }

  .switch-transition-enter {
    transform: translateY(-2rem);
    opacity: 0;
  }


  .hide-nav-enter-active, .hide-nav-reverse-enter-active, .mouse-transition-enter-active {
    transition: 1s;
    transition-delay: 0.7s;
  }

  .hide-nav-leave-active, .hide-nav-reverse-leave-active,
  .mouse-transition-leave-active, .mouse-transition-appear-enter-active {
    transition: 1s;
  }

  .hide-nav-leave-to, .hide-nav-enter {
    transform: translateX(-16rem);
    &.navigation {
      opacity: 0;
    }
  }
  .hide-nav-reverse-leave-to, .hide-nav-reverse-enter {
    transform: translateX(16rem);
  }
  .mouse-transition-enter, .mouse-transition-appear-enter, .mouse-transition-leave-to {
    transform: translateY(16rem);
  }

  // TODO delete this????
  /* Анимации появления и исчезновения могут иметь */
  /* различные продолжительности и динамику.       */
  .slide-fade-enter-active {
    transition: all 0.3s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active до версии 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }
</style>
