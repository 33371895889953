import MenuIcon from "@/components/menu-icon";
import PortfolioNavigation from "@/components/sections/portfolio/PortfolioNavigation";
import WdArrow from "@/components/ui-kit/wd-arrow";
import {mapGetters, mapState} from "vuex";
import {isMobile} from "mobile-device-detect";

export default {
    components: {MenuIcon, PortfolioNavigation, WdArrow},
    name: "PortfolioControls",
    methods: {
        prevPortfolio() {
            this.move(-1);
        },
        nextPortfolio() {
            this.move(1);
        },
        move(direction) {
            this.direction = direction;
            this.$router.push({
                name: "portfolioItem",
                params: {
                    id: this.portfolioList[this.adjustedIndex(this.index + direction)].id,
                    lang: this.$route.params.lang
                },
            });
        },
        adjustedIndex(i) {
            return (i + this.portfolioList.length) % this.portfolioList.length
        }
    },
    computed: {
        ...mapGetters("portfolio", ["portfolioList"]),
        ...mapState("ui", ["mobileMenuOpened"]),
        info() {
            return this.portfolioList[this.index];
        },
        direction: {
            get() {return this.$store.state.portfolio.direction},
            set(v) {this.$store.commit('portfolio/SET_DIRECTION', v)}
        },
        index() {
            return this.portfolioList.findIndex(x => x.id === +this.$route.params.id);
        },
        isMobile() {
            return isMobile;
        }
    }
}
