<template>
 <div class="general_wrapper">
        <!-- <div class="bot_title">
            Uptime tracker
        </div> -->
        <div class="tracker_wrapper" v-if="period">
            <TrackerHead/>
            <TrackerTable v-if="servers"/>
        </div>
    </div>
</template>

<script>
import TrackerHead from "../uptime-tracker/TrackerHead.vue"
import TrackerTable from "../uptime-tracker/TrackerTable"
import {mapActions, mapState} from "vuex";
export default {
    name: "UptimeDashboard",
    components: {TrackerHead, TrackerTable},
    created () {
        this.getPeriod()
    },
    computed: {
        ...mapState('bot', ['servers', 'period']),
    },
    methods: {
        ...mapActions('bot', ['getPeriod']),
    }
}
</script>

<style scoped lang="sass">
    .general_wrapper
        padding: 52px

</style>
