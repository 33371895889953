<template>
    <router-link :to="{ name: 'home', params: {lang: $route.params.lang} }" class="close mobile-disappearing">
        <menu-icon class="portfolio-close btn" opened v-if="isMobile"/>
        <wd-link class="portfolio-close btn" :align="`right`" v-else>{{ $t("dialog.close") }}</wd-link>
    </router-link>
</template>

<script>
import portfolioControl from "@/mixins/portfolioControl";

export default {
    name: "PortfolioCloseControl",
    mixins: [portfolioControl]
}
</script>

<style scoped lang="scss">
    @include mobile {
        .portfolio-close {
            position: fixed;
            top: 1.5rem;
            z-index: 3;
            right: 5rem;
        }
    }
</style>
