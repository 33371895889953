<template>
  <div class="contacts">
    <h1 class="contacts_title">
      {{ $t('contacts.title') }}
    </h1>

    <div class="contacts_grid">
      <wd-link class="contacts_transition_1 contacts_grid_link1" href="mailto:sales@wedo.in.ua">sales@wedo.in.ua
      </wd-link>
      <wd-button
        @click="$root.showDialog(true)"
        class="contacts_grid_button contacts_transition_3"
      >
        {{ $t('contacts.startProject') }}
      </wd-button>
      <div class="contacts_transition_2 contacts_grid_link2">
        <wd-link target="_blank" href="/WeDo Presentation.pdf" download>
          {{ $t('contacts.ourPresentation') }}
        </wd-link>
      </div>
      <div class="contacts_grid_text">
        <div
          :class="'contacts_transition_' + (index + 3)" :key="index"
          class="contacts_text"
          v-for="(text, index) in $t('contacts.description')"
        >
          {{ text }}
        </div>
      </div>
      <div class="contacts_grid_phone1 contacts_transition_1">
        <div class="contacts_phone_label">{{ $t('contacts.phoneUkraine') }}</div>
        <wd-phone href="tel:+380730003901">+380 (73) 000-39-01</wd-phone>
      </div>
      <div class="contacts_grid_phone2 contacts_transition_2">
        <div class="contacts_phone_label">{{ $t('contacts.phoneGermany') }}</div>
        <wd-phone href="tel:+4915259563545">+49 1525 956-3545</wd-phone>
      </div>
    </div>
  </div>
</template>

<script>

  // TODO микроразметка
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/itemscope
  // https://schema.org/Organization

  export default {
    name: 'contacts'
  }
</script>

<style lang="scss">
  .contacts {
    @include desktop {
      padding-top: 10rem;
      padding-right: 8rem;
    }

    @include mobile {
      padding-bottom: 3rem;
    }
  }

  .contacts_title {
    font-weight: 300;
    font-size: 3.6rem;
    line-height: 1.6;
    letter-spacing: 0.08em;
    color: $secondary;
    margin: 2.6rem 0;

    @include desktop {
      max-width: 60rem;
    }

    @include mobile {
      font-size: 2.4rem;
    }
  }

  .contacts_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: start;
    justify-items: start;

    @include desktop {
      grid-template-areas: 'link1 link2 text' 'button ... text' 'phone1 phone2 ...';
    }

    @include mobile {
      grid-template-columns: 1fr;
      grid-template-areas: 'link1' 'link2' 'button' 'phone1' 'phone2';
    }
  }

  .contacts_grid_link1 {
    grid-area: link1;
  }

  .contacts_grid_link2 {
    grid-area: link2;

    @include mobile {
      margin-top: 1.6rem;
    }
  }

  .contacts_grid_text {
    grid-area: text;

    @include mobile {
      display: none;
    }
  }

  .contacts_grid_phone1 {
    grid-area: phone1;

    @include mobile {
      margin-top: 4rem;
    }
  }

  .contacts_grid_phone2 {
    grid-area: phone2;

    @include mobile {
      margin-top: 3.2rem;
    }
  }

  .contacts_grid_button {
    grid-area: button;

    @include mobile {
      margin-top: 4rem;
    }
  }

  .contacts_text {
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.45;
    letter-spacing: 0.04em;
    color: $primary-light;
    margin-bottom: 2.5rem;

    &:last-child {
      margin-bottom: 1rem;
    }
  }

  .contacts_phone_label {
    font-size: 1.4rem;
    line-height: 1.7;
    color: $primary;
    margin-bottom: 3rem;

    @include mobile {
      margin-bottom: 1.6rem;
    }
  }
</style>
