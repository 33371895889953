<template>
  <label
    :class="{
        'is_focus': focus,
        'has_value': value !== ''
      }"
    class="wd_textarea"
  >
    <div class="wd_textarea_border wd_textarea_border_left wd_textarea_border_left_1"/>
    <div class="wd_textarea_border wd_textarea_border_left wd_textarea_border_left_2"/>

    <div class="wd_textarea_border wd_textarea_border_right wd_textarea_border_right_1"/>
    <div class="wd_textarea_border wd_textarea_border_right wd_textarea_border_right_2"/>
    <div class="wd_textarea_border wd_textarea_border_right wd_textarea_border_right_3"/>

    <div class="wd_textarea_border wd_textarea_border_top wd_textarea_border_top_1"/>
    <div class="wd_textarea_border wd_textarea_border_top wd_textarea_border_top_2"/>
    <div class="wd_textarea_border wd_textarea_border_top wd_textarea_border_top_3"/>
    <div class="wd_textarea_border wd_textarea_border_top wd_textarea_border_top_4"/>
    <div class="wd_textarea_border wd_textarea_border_top wd_textarea_border_top_5"/>

    <div class="wd_textarea_border wd_textarea_border_bottom wd_textarea_border_bottom_1"/>
    <div class="wd_textarea_border wd_textarea_border_bottom wd_textarea_border_bottom_2"/>
    <div class="wd_textarea_border wd_textarea_border_bottom wd_textarea_border_bottom_3"/>
    <div class="wd_textarea_border wd_textarea_border_bottom wd_textarea_border_bottom_4"/>
    <div class="wd_textarea_border wd_textarea_border_bottom wd_textarea_border_bottom_5"/>


    <textarea
      :value="value"
      @blur="focus = false"
      @focus="focus = true"
      @input="$emit('input', $event.target.value)"
      rows="4"
    />
    <span>
      {{label}}
    </span>
  </label>
</template>

<script>
  export default {
    name: 'wd-textarea',

    props: {
      label: {
        type: String,
        required: true
      },
      value: String
    },

    data() {
      return {
        focus: false
      }
    }
  }
</script>

<style lang="scss">
  $side-padding: 1.6rem;
  $label-width: 19.2rem;
  $textarea-width: 24rem;

  .wd_textarea {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 0.8rem $side-padding;
    position: relative;
    cursor: pointer;
    margin-bottom: 2.5rem;

    @include desktop {
      width: 46.4rem;
    }

    @include mobile {
      flex-direction: column-reverse;
    }

    span {
      font-weight: 300;
      font-size: 1.2rem;
      line-height: 2;
      letter-spacing: 0.16em;
      text-transform: uppercase;
      color: $primary;
      width: $label-width;
    }

    textarea {
      resize: none;
      background: none;
      border: none;
      color: $secondary;
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.7;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      width: $textarea-width;

      &:focus {
        color: $secondary-light;
        outline: none;

        & + span {
          color: $primary-light;
        }
      }
    }
  }

  .wd_textarea_border {
    background: $primary;
    position: absolute;
    transition: .2s;
  }

  .wd_textarea_border_left {
    top: 0;
    left: 0;
    width: 1px;
    height: 16%;
  }

  .wd_textarea_border_right {
    bottom: 0;
    right: 0;
    width: 1px;
    height: 16%;
  }

  .wd_textarea_border_bottom {
    left: $side-padding;
    bottom: 0;
    width: 20%;
    height: 1px;
  }

  .wd_textarea_border_top {
    right: -1rem;
    top: 0;
    width: 0;
    height: 1px;
  }

  @include desktop {
    .wd_textarea:hover {
      .wd_textarea_border {
        background: $primary-light;
      }

      .wd_textarea_border_left_1 {
        height: 8%;
      }

      .wd_textarea_border_left_2 {
        top: 76%;
        height: 24%;
      }

      .wd_textarea_border_right_1 {
        bottom: 53%;
        height: 13%;
      }

      .wd_textarea_border_right_2 {
        bottom: 73%;
        height: 3%;
      }

      .wd_textarea_border_right_3 {
        bottom: 90%;
        height: 13%;
      }

      .wd_textarea_border_top_1 {
        right: 0;
        width: 20%;
      }

      .wd_textarea_border_top_2 {
        right: 38%;
        width: 7%;
      }

      .wd_textarea_border_top_3 {
        right: 75%;
        width: 3%;
      }

      .wd_textarea_border_top_4 {
        right: 93%;
        width: 2%;
      }

      .wd_textarea_border_top_5 {
        right: 98%;
        width: 3%;
      }

      .wd_textarea_border_bottom_1 {
        left: $label-width + $side-padding;
        width: ($textarea-width + $side-padding) * 0.19;
      }

      .wd_textarea_border_bottom_2 {
        left: $label-width + $side-padding + ($textarea-width + $side-padding) * 0.28;
        width: ($textarea-width + $side-padding) * 0.06;
      }

      .wd_textarea_border_bottom_3 {
        left: $label-width + $side-padding + ($textarea-width + $side-padding) * 0.37;
        width: ($textarea-width + $side-padding) * 0.09;
      }

      .wd_textarea_border_bottom_4 {
        left: $label-width + $side-padding + ($textarea-width + $side-padding) * 0.87;
        width: ($textarea-width + $side-padding) * 0.03;
      }

      .wd_textarea_border_bottom_5 {
        left: $label-width + $side-padding + ($textarea-width + $side-padding) * 0.94;
        width: ($textarea-width + $side-padding) * 0.06;
      }
    }
  }

  .wd_textarea.is_focus {
    .wd_textarea_border {
      background: $primary-light;
    }

    .wd_textarea_border_left_1 {
      top: 6%;
      height: 20%;
    }

    .wd_textarea_border_left_2 {
      top: 96%;
      height: 4%;
    }

    .wd_textarea_border_right_1 {
      bottom: 69%;
      height: 6%;
    }

    .wd_textarea_border_right_2 {
      bottom: 82%;
      height: 3%;
    }

    .wd_textarea_border_right_3 {
      bottom: 89%;
      height: 13%;
    }

    .wd_textarea_border_top_1 {
      right: 0;
      width: ($textarea-width + $side-padding) * 0.16;
    }

    .wd_textarea_border_top_2 {
      right: ($textarea-width + $side-padding) * 0.18;
      width: ($textarea-width + $side-padding) * 0.06;
    }

    .wd_textarea_border_top_3 {
      right: ($textarea-width + $side-padding) * 0.66;
      width: ($textarea-width + $side-padding) * 0.03;
    }

    .wd_textarea_border_top_4 {
      right: ($textarea-width + $side-padding) * 0.73;
      width: ($textarea-width + $side-padding) * 0.13;
    }

    .wd_textarea_border_top_5 {
      right: ($textarea-width + $side-padding) * 0.91;
      width: ($textarea-width + $side-padding) * 0.09;
    }

    .wd_textarea_border_bottom_1 {
      left: $label-width + $side-padding;
      width: ($textarea-width + $side-padding) * 0.38;

      @include mobile {
        left: $side-padding;
        width: 38%;
      }
    }

    .wd_textarea_border_bottom_2 {
      left: $label-width + $side-padding + ($textarea-width + $side-padding) * 0.53;
      width: ($textarea-width + $side-padding) * 0.03;

      @include mobile {
        left: 53%;
        width: 3%;
      }
    }

    .wd_textarea_border_bottom_3 {
      left: $label-width + $side-padding + ($textarea-width + $side-padding) * 0.59;
      width: ($textarea-width + $side-padding) * 0.16;

      @include mobile {
        left: 59%;
        width: 16%;
      }
    }

    .wd_textarea_border_bottom_4 {
      left: $label-width + $side-padding + ($textarea-width + $side-padding) * 0.92;
      width: ($textarea-width + $side-padding) * 0.01;

      @include mobile {
        left: 92%;
        width: 1%;
      }
    }

    .wd_textarea_border_bottom_5 {
      left: $label-width + $side-padding + ($textarea-width + $side-padding) * 0.92;
      width: ($textarea-width + $side-padding) * 0.08;

      @include mobile {
        left: 92%;
        width: 8%;
      }
    }
  }

  .wd_textarea.has_value:not(.is_focus):not(:hover) {
    .wd_textarea_border_bottom_2 {
      left: 76%;
      width: 20%;
    }
  }
</style>
