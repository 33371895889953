<template>
  <svg :class="{opened}" class="menu_icon" fill="none" height="32" viewBox="0 0 32 32" width="32"
       xmlns="http://www.w3.org/2000/svg">
    <template v-if="opened">
      <path d="M6.96577 25.6L9.22696 23.3388L8.66127 22.7731L6.40008 25.0343L6.96577 25.6Z"/>
      <path d="M23.2037 22.6379L25.6001 25.0343L25.0344 25.6L22.638 23.2036L23.2037 22.6379Z"/>
      <path d="M6.96564 6.39999L19.2065 18.6408L18.6408 19.2065L6.39995 6.96568L6.96564 6.39999Z"/>
      <path d="M22.768 8.66275L25.0307 6.40001L25.5964 6.9657L23.3337 9.22844L22.768 8.66275Z"/>
      <path d="M21.1656 10.2624L12.1146 19.3134L12.6803 19.8791L21.7312 10.8281L21.1656 10.2624Z"/>
    </template>

    <template v-else>
      <path d="M25.6 21.6H12.8V22.4H25.6V21.6Z"/>
      <path d="M22.2109 15.6L25.5999 15.6V16.4L22.2109 16.4V15.6Z"/>
      <path d="M6.39999 15.6H19.1999V16.4H6.39999V15.6Z"/>
      <path d="M15.8109 9.60001H25.5999V10.4L15.8109 10.4V9.60001Z"/>
      <path d="M12.7999 9.60001L6.39999 9.60001V10.4L12.7999 10.4V9.60001Z"/>
    </template>
  </svg>
</template>

<script>
  export default {
    name: 'menu-icon',
    props: {
      opened: Boolean
    }
  }
</script>

<style lang="scss">
  .menu_icon {
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    z-index: 10;

    @include desktop {
      display: none;
    }

    path {
      fill: rgb(var(--text-contrast));
    }

    &.opened {
      path {
        fill: $secondary;
      }
    }
  }
</style>
