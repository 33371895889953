export default {
  router: {
    home: 'Home',
    about: 'About us',
    services: 'Services',
    contacts: 'Contacts',
    portfolio: 'Portfolio',
  },
  app: {
    portfolio: 'Portfolio'
  },
  dialog: {
    title: 'Begin your journey',
    name: 'Name',
    phone: 'Phone',
    email: 'E-mail',
    description: 'Description',
    submit: 'Submit',
    close: 'Close',
    back: 'Back'
  },
  orderNowButton: 'I\'m in!',
  uiKit: {
    file: {
      caption: 'Attach file'
    },
    fileList: {
      label: 'Attachments'
    },
    input: {
      error: {
        required: 'The field is required',
        email: 'Email does not match format'
      }
    }
  },
  home: {
    occupations: [
      'websites',
      'design',
      'promotion',
      'chatbots'
    ],
    brandName: 'WEDO',
    subtitle: 'We do what You need'
  },
  about: {
    view1: {
      title: 'We provide outsourcing solutions for your business',
      technology: {
        title: 'Technologies',
        list: [
          'We create adaptive design',
          'Our coders use Laravel and Yii',
          'Our HTML5 + CSS3 layouts are modern and animated',
          'We develop front-end using Vue.js, React and Angular'
        ]
      },
      clients: {
        title: 'Clients',
        description: 'We cowork with ambitious companies, creating new projects and enhancing the existing ones. ' +
          'With us, You will have your project implemented exactly the way you imagined it '
      }
    },
    view2: {
      title: 'We make',
      description: 'Websites, services, administration systems, loyalty programs, Facebook Messenger, WhatsApp, Telegram and Skype chatbots. ' +
        'Based on the analysis of Your company we are ready to support the product during the whole lifecycle, from brand development to promotion.'
    },
    view3: {
      title: 'Expertise',
      competitions: {
        1: 'Chatbots',
        2: 'Frontend',
        3: 'Backend',
        4: 'UI/UX-Design',
        5: 'Prototyping',
        6: 'UX-analytics'
      }
    }
  },
  services: {
    serviceItem: {
      priceFrom: 'from'
    },
    title: 'Here\'s what we can do:',
    sections: [{
      caption: 'Web development',
      items: [{
        title: 'Chatbots development',
        price: 1000,
        text: 'Smart and friendly messenger bots ' +
          'will help You save costs and automate your sales pipelines, ' +
          'while saving your support professionals` valuable time'
      }, {
        title: 'Corporate websites',
        price: 1500,
        text: 'Websites for enterprise of any level, ' +
          'designed to express Your identity and find solutions your business objectives'
      }, {
        title: 'Landing pages',
        price: 1000,
        text: 'Target pages help convert your visitors into customers'
      }, {
        title: 'Web stores',
        price: 5000,
        text: 'Comfortable web store with intuitive navigation is an irreplaceable for the start of Your online sales'
      }, {
        title: 'Specifications development',
        price: 200,
        text: 'Our expert turns your directions into understandable and unambiguous specs for developers, ' +
          'devises the structure of the future project, composes a list of achievable tasks and communicates everything to your developers'
      }]
    }, {
      caption: 'Design',
      items: [{
        title: 'Brand design',
        price: 700,
        text: 'A logo with guidelines is developed ' +
          'and presented on information carriers to help Your business get started'
      }, {
        title: 'UI/UX-design',
        price: 500,
        text: 'High quality and usability UX Design will let Your business achieve your goals'
      }, {
        title: 'Graphic design',
        price: 100,
        text: 'Graphic elements, illustrations, typographic layouts, ' +
          'business cards, flyers, banners - whatever You find desirable'
      }]
    },

    //   {
    //   caption: 'Marketing',
    //   items: [{
    //     title: 'SEO',
    //     price: 1000,
    //     text: 'We\'ll help search engines and your website find each other. ' +
    //       'Semantics collection, competition analysis, link purchase, SEO optimization and so much more'
    //   }, {
    //     title: 'SEO-audit',
    //     price: 400,
    //     text: 'Our SEO guru reviews the architecture, content and link structure of the website ' +
    //       'and helps correct the mistakes that may slow down the growth of Your website'
    //   }, {
    //     title: 'PPC',
    //     price: 1000,
    //     text: 'Development of Your campaign strategy, support and advising'
    //   }, {
    //     title: 'Ad campaign audit',
    //     price: 400,
    //     text: 'An all-encompassing analysis and review of Your AdWords and Facebook ' +
    //       'campaigns may save You up to 60% of Your ad costs'
    //   }]
    // }

    ]
  },
  contacts: {
    title: 'Let us develop Your best solution',
    startProject: 'Start project',
    ourPresentation: 'Our presentation',
    description: [
      'We are a cutting edge company. We found each other to make art in the field of web development.',
      'To Your avail is a cohesive team of talented professionals and a winning strategy of web development and digital marketing.',
      'Outsourcing – and that\'s it!'
    ],
    phoneUkraine: 'Ukraine',
    phoneGermany: 'Germany',
    thankYou: {
      title: 'A great beginning!',
      sections: [{
        title: 'Your personal manager is here',
        text: 'A professional account manager will get in touch with you soon.'
      }, {
        title: 'We value your order',
        text: 'We are glad that you have contacted us. And we will make every effort to ensure that your project avoids the errors made by other developers.'
      }],
      button: 'See you soon!'
    }
  },
  seo: {
    home: {
      title: 'WeDo – Website development, messenger bots, design',
      metaTags: [{
        name: 'description',
        content: 'WeDo ⇒ We develop and support web projects. 👩‍💻 Chatbot development for ' +
          'Facebook Messenger, Telegram, Skype. 🤖 Graphic design, UI/UX-design, consulting'
      }]
    },

    about: {
      title: 'WeDo – About',
      metaTags: [{
        name: 'description',
        content: 'We treat design and development like art. 🎭 Websites, services, ' +
          'loyalty programs, administration systems.'
      }]
    },

    services: {
      title: 'WeDo – Services',
      metaTags: [{
        name: 'description',
        content: 'WeDo offers graphic design, UI/UX-design, website and landing page design. 🎨' +
          'Digital marketing services include SEO, SEO-audit, PPC 🌐'
      }]
    },

    contacts: {
      title: 'WeDo – Contacts',
      metaTags: [{
        name: 'description',
        content: 'WeDo company contacts. 📞 You may call us +380(73) 000-39-01. 📧 Contact us: sales@wedo.in.ua'
      }]
    },

    portfolio: {
      title: 'WeDo – Portfolio',
      metaTags: [{
        name: 'description',
        content: 'WeDo has experience in graphic design, UI/UX-design, website and landing page design' +
            'as well as Chatbot development 🤖 and general web development 🌐'
      }]
    },

    portfolioItem: {
      title: 'WeDo – Portfolio', // TODO finish this
      metaTags: [{
        name: 'description',
        content: 'WeDo has experience in graphic design, UI/UX-design, website and landing page design' +
            'as well as Chatbot development 🤖 and general web development 🌐'
      }]
    }
  }
}
