<template>
    <PortfolioNavigation class="mobile-disappearing" v-if="info" :name="info.name" @prev="prevPortfolio" @next="nextPortfolio"/>
</template>

<script>
    import portfolioControl from "@/mixins/portfolioControl";

    export default {
        name: 'PortfolioNavigationControl',
        mixins: [portfolioControl]
    }
</script>

<style scoped lang="scss">
    @include mobile {
        .another-portfolio {
            position: fixed;
            width: 100vw;
            display: flex;
            justify-content: center;
            top: 1.5rem;
            left: 0;
            pointer-events: none;
            z-index: 3;
        }
    }
</style>
