<template>
    <div class="switch"
         :class="{on: value}"
         @click="toggle()">
    </div>
</template>

<script>
     export default {
          name: "wd-switch",
          props: {
               value: Boolean,
          },
          methods: {
               toggle() {
                    this.$emit('input', !this.value);
               }
          }
     }
</script>

<style scoped lang="scss">

  @import "src/css/vars";

  .switch {
    width: 2.4rem;
    height: 1.44rem;
    position: relative;
    border-radius: 2.4rem;
    border: 0.1rem solid $secondary;
    box-sizing: border-box;
    cursor: pointer;
    transition: all $transition-duration;

    &:before {
      content: '';
      background-color: $secondary;
      position: absolute;
      width: 0.96rem;
      height: 0.96rem;
      border-radius: 50%;
      top: 0.14rem;
      left: 0.1rem; // + 0.09
      transition: all $transition-duration;
    }

    &.on {
      border-color: $primary;

      &:before {
        left: 1rem; // + 0.09
        background-color: $primary;
      }
    }
  }

</style>
