<template>
  <div class="portfolio-item-info portfolio-container" :style="{'--direction': direction}" :class="{mobileMenuOpened}">
    <template v-if="info">
      <div class="info-grid">
        <PortfolioCloseControl/>
        <PortfolioNavigationControl/>
        <PortfolioBackControl/>
        <transition :name="transitionName">
          <h1 class="portfolio-name title" :key="`${key}-h1`">
            {{ info.main_title }}
          </h1>
        </transition>
        <transition :name="transitionName">
          <div
              class="image"
              :style="{ backgroundImage: `url(${info.preview})` }"
              :key="`${key}-image`"
          ></div>
        </transition>
        <transition :name="transitionName">
          <span class="type" :key="`${key}-type`">{{ info.type }}</span>
        </transition>
        <transition name="about">
          <transition :name="transitionName">
            <div class="portfolio-about" :key="`${key}-about`">
              <h1>About</h1>
              <p class="text">{{ info.text }}</p>
              <a :href="`https://${info.url}`" target="_blank" rel="nofollow" v-if="info.url">
                <wd-link class="site">{{ info.url }}</wd-link>
              </a>
            </div>
          </transition>
        </transition>
      </div >
      <div v-for="(attachment, i) of info.attachments" class="screen-container">
        <transition :name="transitionName">
          <div class="screen" :key="`attachment-${key}-${i}`">
            <img v-if="attachment.type === 'image'" :alt="`attachment-${info.id}-${i}`" :src="`${attachment.src}`">
            <video controls v-if="attachment.type === 'video'" :src="`${attachment.src}`"></video>
          </div>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>

// TODO change background images to semantic img tags
// TODO on portfolio close return to active landing page (via vuex)

import {mapGetters, mapState} from "vuex";
import PortfolioCloseControl from "@/components/sections/portfolio/Controls/PortfolioCloseControl";
import PortfolioNavigationControl from "@/components/sections/portfolio/Controls/PortfolioNavigationControl";
import PortfolioBackControl from "@/components/sections/portfolio/Controls/PortfolioBackControl";


export default {
  name: 'PortfolioItemInfo',
    components: {PortfolioBackControl, PortfolioNavigationControl, PortfolioCloseControl},
    data() {
    return {
      transitionName: 'appear'
    };
  },
  computed: {
      ...mapGetters("portfolio", ["portfolioList"]),
      ...mapState("ui", ["mobileMenuOpened"]),
      info() {
          return this.portfolioList[this.index];
      },
      key() {
          return this.info.id;
      },
      direction: {
          get() {return this.$store.state.portfolio.direction},
          set(v) {this.$store.commit('portfolio/SET_DIRECTION', v)}
      },
      index() {
          return this.portfolioList.findIndex(x => x.id === +this.$route.params.id);
      },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'portfolioItem')
      this.transitionName = 'slide';
    else this.transitionName = 'appear';
    next();
  }
};
</script>

<style lang="scss" scoped>

// TRANSITIONS

.slide-leave-active,
.slide-enter-active {
  transition: $transition-duration;
}
.slide-enter {
  transform: translate(calc(100vw * var(--direction)), 0);
  opacity: 0;
}
.slide-leave-to {
  transform: translate(calc(-100vw * var(--direction)), 0);
  opacity: 0;
}
.screen {
  &.slide-enter-active {
    transition-delay: 0.7s;
  }
  &.slide-enter, &.slide-leave-to {
    transform: translate(0, 50vh);
  }
}


.portfolio-item-info {

  display: flex;
  flex-direction: column;
  width: 100vw;
  position: absolute;
  z-index: 2;
  left: calc(100% - 100vw);

  .info-grid {
    display: grid;
    grid-template-columns: 38.4rem 45.2rem 15.3rem;
    align-items: center;
    justify-items: start;
    margin: 7.3rem 0 4rem 21rem;

    @include desktop {
      grid-template-areas:
        ". another close"
        "title type back"
        "img about .";
    }

    .close {
      grid-area: close;
      align-self: start;
      justify-self: end;
    }

    .back {
      grid-area: back;
      align-self: end;
      justify-self: end;
    }

    .title {
      margin: 6.6rem 0 0 0;
      font-weight: 300;
      font-size: 3.6rem;
      line-height: 1.33;
      color: $secondary;
      letter-spacing: 0.08em;
      grid-area: title;

      @include mobile {
        font-size: 2.4rem;
      }
    }

    .btn {
      font-weight: 300;
      font-size: 1.2rem;
      color: $secondary;
    }

    .image {
      margin: 9rem 0 0 0;
      width: 38.4rem;
      height: 20.8rem;
      background-size: contain;
      grid-area: img;
      z-index: 2;
    }

    .type {
      align-self: end;
      justify-self: end;
      grid-area: type;
      margin-right: 3.4rem;
      font-size: 1.4rem;
      font-weight: 400;
      color: $primary;
    }

    .portfolio-about {
      align-self: start;
      margin: 9rem 2.5rem 0 4.3rem;
      padding: 0;
      grid-area: about;

      h1 {
        color: $secondary;
        font-family: "NotoSans",serif;
        font-size: 2.4rem;
        font-weight: 700;
        letter-spacing: 0.08em;
      }

      p {
        margin: 1.6rem 0 0 0;
        color: $primary;
        font-family: "NotoSans",serif;
        font-size: 1.4rem;
        font-weight: 400;
      }

      .site {
        margin: 1.6rem 0 0 0;
        color: $primary;
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: 0.16em;
        text-transform: uppercase;
      }
    }
  }

  .portfolio-close {
    position: absolute;
    font-weight: 300;
    font-size: 1.2rem;
    color: $secondary;
    right: 8rem;
    top: 7.3rem;
  }

  .screen-container {
    width: 100%;
    position: relative;

    > :nth-of-type(2) {
      position: absolute;
      top: 0;
    }
  }

  .screen {
    // For images
    margin: 0 auto -0.4rem;
    width: 100%;
    display: flex;
    img {
      width: 100%;
      user-drag: none;
      -moz-user-select: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    video {
      width: calc(100% - 8rem);
      margin: 4rem;
      outline: none;
      @include mobile {
        width: 100%;
        margin: 0;
      }
    }
  }

  @include mobile {
    z-index: 2;

    .info-grid {
      padding-left: 2rem;
      padding-right: 2rem;

      width: 100%;
      display: flex;
      margin: 0;
      flex-direction: column;

      .title {
        display: none;
      }

      .image {
        margin-top: 3rem;
        width: 32rem;
        height: 17.33rem;

        &:nth-of-type(3) {
          position: relative;
          top: -20.33rem;
          margin-bottom: -20.33rem;
        }
      }

      .type {
        display: none;
      }

      .portfolio-about {
        margin: 3rem 0;
      }
    }

  }
}
</style>
