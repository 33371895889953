<template>
  <div class="service_item">
    <div class="service_item_content">
      <div class="service_item_border service_item_border_top service_item_border_top_1"/>
      <div class="service_item_border service_item_border_top service_item_border_top_2"/>
      <div class="service_item_border service_item_border_top service_item_border_top_3"/>
      <div class="service_item_border service_item_border_top service_item_border_top_4"/>
      <div class="service_item_border service_item_border_top service_item_border_top_5"/>

      <div class="service_item_border service_item_border_left service_item_border_left_1"/>
      <div class="service_item_border service_item_border_left service_item_border_left_2"/>

      <div class="service_item_border service_item_border_bottom service_item_border_bottom_1"/>
      <div class="service_item_border service_item_border_bottom service_item_border_bottom_2"/>
      <div class="service_item_border service_item_border_bottom service_item_border_bottom_3"/>
      <div class="service_item_border service_item_border_bottom service_item_border_bottom_4"/>
      <div class="service_item_border service_item_border_bottom service_item_border_bottom_5"/>
      <div class="service_item_border service_item_border_bottom service_item_border_bottom_6"/>

      <h2 class="service_item_title">
        {{title}}
      </h2>
      <p class="service_item_text">
        {{text}}
      </p>
    </div>
    <div class="service_item_price">
      <div class="service_item_price_head">
        <div class="service_item_price_head_from">{{ $t('services.serviceItem.priceFrom') }}</div>
        <div :class="{ hover }" class="service_item_price_head_number">{{price}}$</div>
      </div>
      <wd-button
        @click="$root.showDialog(true)"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
        invert
      >
        {{ $t('orderNowButton') }}
      </wd-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'service-item',
    props: {
      title: String,
      price: Number,
      text: String
    },

    data() {
      return {
        hover: false
      }
    }
  }
</script>

<style lang="scss">
  .service_item {
    margin-bottom: 0.8rem;

    @include desktop {
      display: flex;

      &:hover .service_item_price {
        transform: none;
      }
    }

    @include mobile {
      margin-bottom: 4rem;
    }
  }

  .service_item_content {
    padding: 0.8rem 2.4rem;
    position: relative;

    @include desktop {
      width: 53rem;
    }

    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .service_item_title {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 1.35;
    letter-spacing: 0.08em;
    color: $secondary-light;
    margin-bottom: 1.6rem;

    @include mobile {
      font-size: 1.4rem;
    }
  }

  .service_item_text {
    font-size: 1.4rem;
    line-height: 1.7;
    color: $primary-light;
  }

  .service_item_price {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @include desktop {
      padding-top: 0.8rem;
      padding-left: 2.4rem;
      padding-bottom: 1.6rem;
      transition: .2s;
      transform: translateX(100%);
      background: $secondary-light;
    }

    @include mobile {
      padding-top: 1rem;
      flex-direction: row-reverse;
    }
  }

  .service_item_price_head_from {
    font-weight: 300;
    font-size: 1.1rem;
    line-height: 1.45;
    letter-spacing: 0.04em;
    margin-right: 4.5rem;

    @include desktop {
      color: #fff;
    }

    @include mobile {
      margin-right: 1rem;
    }
  }

  .service_item_price_head {
    display: flex;
    align-items: baseline;
  }

  .service_item_price_head_number {
    font-size: 2.4rem;
    line-height: 1.35;
    letter-spacing: 0.08em;
    font-weight: 700;
    transform-origin: top right;

    @include desktop {
      transition: .2s;
      color: #fff;

      &.hover {
        transform: scale(1.3);
      }
    }
  }

  .service_item_border {
    background: $secondary-light;
    position: absolute;
    transition: .6s;

    @include mobile {
      display: none;
    }
  }

  .service_item_border_top {
    top: 0;
    height: 1px;
    right: -30%;
    width: 0;
  }

  .service_item_border_left {
    left: 0;
    width: 1px;
    height: 0;
  }

  .service_item_border_bottom {
    bottom: 0;
    height: 1px;
    left: -10%;
    width: 0;
  }

  .service_item_border_left_1 {
    top: -10%;
  }

  .service_item_border_left_2 {
    bottom: -10%;
  }

  .service_item:hover {
    .service_item_border_top_1 {
      width: 3%;
      right: 29%;
    }

    .service_item_border_top_2 {
      width: 7%;
      right: 34%;
    }

    .service_item_border_top_3 {
      width: 1%;
      right: 82%;
    }

    .service_item_border_top_4 {
      width: 2%;
      right: 86%;
    }

    .service_item_border_top_5 {
      width: 12%;
      right: 92%;
    }

    .service_item_border_left_1 {
      height: 36%;
      top: 71%;
    }

    .service_item_border_left_2 {
      height: 29%;
      bottom: 79%;
    }

    .service_item_border_bottom_1 {
      width: 7%;
      left: -2%;
    }

    .service_item_border_bottom_2 {
      width: 2%;
      left: 28%;
    }

    .service_item_border_bottom_3 {
      width: 8%;
      left: 31%;
    }

    .service_item_border_bottom_4 {
      width: 1%;
      left: 46%;
    }

    .service_item_border_bottom_5 {
      width: 4%;
      left: 86%;
    }

    .service_item_border_bottom_6 {
      width: 2%;
      left: 93%;
    }
  }
</style>
