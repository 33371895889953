<template>
  <div class="wd_file_list_container">
    <div class="wd_file_list_label">
      {{ $t('uiKit.fileList.label') }}
    </div>
    <div class="wd_file_list">
      <div
        class="wd_file_list_item"
        v-for="file in value"
      >
        <svg @click="remove(file)" class="wd_file_list_delete_icon" fill="none" height="40" viewBox="0 0 40 40"
             width="40"
             xmlns="http://www.w3.org/2000/svg"
        >
          <path clip-rule="evenodd"
                d="M10.5 12.5H29.5V32.5H10.5V12.5ZM11.5 13.5V31.5H16.5V13.5H11.5ZM17.5 13.5V31.5H22.5V13.5H17.5ZM23.5 13.5V31.5H28.5V13.5H23.5Z"
                fill="rgb(var(--text-contrast))"
                fill-rule="evenodd"/>
          <path class="wd_file_list_delete_icon_path" d="M16.5 8.5V9.5H10.5V10.5H29.5V9.5H23.5V8.5H16.5Z"
                fill="rgb(var(--text-contrast))"/>
        </svg>
        <span>{{file.name}}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'wd-file-list',

    props: {
      value: Array
    },

    methods: {
      remove(file) {
        this.$emit('input', this.value.filter(f => f !== file))
      }
    }
  }
</script>

<style lang="scss">
  .wd_file_list_container {
    padding-left: 1.6rem;
    margin-bottom: 2rem;
    display: flex;


    @include desktop {
      width: 46.4rem;
    }

    @include mobile {
      padding-left: 0;
    }
  }

  .wd_file_list_label {
    width: 19.2rem;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 2;
    letter-spacing: 0.16em;
    text-transform: uppercase;
    color: $primary;

    @include mobile {
      display: none;
    }
  }

  .wd_file_list {
    flex-grow: 1;
    position: relative;
  }

  .wd_file_list_item {
    font-size: 1.4rem;
    line-height: 1.7;
    color: $secondary;
    margin-bottom: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;

    span {
      transition: .2s;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .wd_file_list_delete_icon {
    cursor: pointer;

    path {
      transition: .2s;
    }

    @include desktop {
      &:hover {
        path {
          fill: $secondary;
        }

        .wd_file_list_delete_icon_path {
          transform: rotate(15deg);
        }

        & + span {
          transform: translateX(3.2rem);
          opacity: 0.4;
        }
      }
    }
  }

  .wd_file_list_delete_icon_path {
    transition: .2s;
    transform-origin: right;
  }
</style>
