<template>
  <label class="wd_file">
    <wd-link align="right">{{ $t('uiKit.file.caption') }}</wd-link>
    <input type="file" @change="change" multiple>
  </label>
</template>

<script>
  export default {
    name: 'wd-file',

    methods: {
      change(e) {
        for (const file of [...e.target.files]) {
          this.$emit('input', file)
        }
        e.target.value = ''
      }
    }
  }
</script>

<style lang="scss">
  .wd_file {
    cursor: pointer;

    input {
      display: none;
    }
  }
</style>
