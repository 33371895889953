import WdButton from './wd-button'
import WdLink from './wd-link'
import WdPhone from './wd-phone'
import WdFile from './wd-file'
import WdInput from './wd-input'
import WdTextarea from './wd-textarea'
import WdFileList from './wd-file-list'

export default {
  install(Vue) {
    Vue.component(WdButton.name, WdButton)
    Vue.component(WdLink.name, WdLink)
    Vue.component(WdPhone.name, WdPhone)
    Vue.component(WdFile.name, WdFile)
    Vue.component(WdInput.name, WdInput)
    Vue.component(WdTextarea.name, WdTextarea)
    Vue.component(WdFileList.name, WdFileList)
  }
}
