<template>
  <div class="item-container" @click="selectItem">
    <div class="image" :style="{ backgroundImage: `url(${data.preview})` }"></div>
    <div class="info">
      <span class="name">{{ data.main_title }}</span>
      <span class="type">{{ data.type }}</span>
    </div>
  </div>
</template>

<script>
// TODO change layouts with semantic tags (img, p, h3 etc)

import { mapMutations } from "vuex";
export default {
  props: {
    data: {},
  },
  data() {
    return {};
  },
  methods: {
    selectItem() {
      this.$router.push({
        name: 'portfolioItem',
        params: {
          id: this.data.id,
          lang: this.$route.params.lang
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-container {
  flex: none;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 4rem;
  }

  .image {
    width: 38.4rem;
    height: 20.8rem;
    background-size: contain;
    transition: all 0.5s;
    @include mobile {
      width: 28rem;
      height: 15.16rem;
    }
  }

  .info {
    width: 100%;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;

    span {
      display: block;
      width: 50%;
    }

    .name {
      font-weight: 400;
      font-size: 1.4rem;
      color: $secondary;
    }

    .type {
      font-weight: 400;
      font-size: 1.1rem;
      color: $primary;
      text-align: end;
    }
  }

  &:hover > .image {
    transform: scale(1.03);
  }
}
</style>
