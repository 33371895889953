<template>
  <div>
    <h1 class="about_title">
      {{ $t('about.view1.title') }}
    </h1>
    <div class="about_sections about_content">
      <div class="about_section">
        <div class="about_section_title">{{ $t('about.view1.technology.title') }}</div>
        <ul class="about_section_list">
          <li
            :key="index"
            class="about_section_list_item" v-for="(item, index) in $t('about.view1.technology.list')"
          >
            {{ item }}
          </li>
        </ul>
      </div>
      <div class="about_section">
        <div class="about_section_title">{{ $t('about.view1.clients.title') }}</div>
        <div>
          {{ $t('about.view1.clients.description') }}
        </div>
      </div>
    </div>

    <mobile-arrow
      @click.native="$emit('next-page', 2)"
      key="about1"
    />
  </div>
</template>

<script>
  import MobileArrow from '../../mobile-arrow'

  export default {
    name: 'page-1',

    components: {
      MobileArrow
    }
  }
</script>

<style lang="scss">
  .about_sections {
    counter-reset: section;

    @include desktop {
      display: flex;
      margin-top: 8.5rem;
    }
  }

  .about_section {
    padding-top: 2rem;
    padding-left: 4rem;
    position: relative;
    color: $primary-light;
    font-size: 1.4rem;
    line-height: 1.7;

    @include desktop {
      width: 50%;
    }

    @include mobile {
      margin-top: 4rem;
    }

    &:before {
      counter-increment: section;
      content: counter(section);
      pointer-events: none;
      font-size: 13rem;
      color: $primary-light;
      font-weight: 900;
      opacity: 0.1;
      position: absolute;
      top: 0;
      left: 0;
      line-height: 0.7;
    }
  }

  .about_section_title {
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 1.35;
    letter-spacing: 0.08em;
    color: $secondary-light;
    margin-bottom: 1.6rem;
  }

  .about_section_list {
    list-style: disc;
  }

  .about_section_list_item {
    margin-bottom: 0.08rem;
  }
</style>
