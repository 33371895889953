import Vue from 'vue'
import createRouter from './router'

import 'reset-css'
import './css/index.scss'
import i18n from './i18n'
import store from './store/index'
import Controls from './components/ui-kit/install'
import App from './app.vue'
import Vuelidate from 'vuelidate'
import UptimeDashboard from "@/components/uptime-tracker/UptimeDashboard";

Vue.use(Controls)
Vue.use(Vuelidate)

Vue.config.productionTip = false

// (Later)
// TODO make portfolio carousel looped (no beginning and end)
// TODO move to end of block on scroll up
// TODO rosy grid on hover (see prototype)
// TODO portfolio SEO data (incl. pre-rendering)
// TODO don't transition from white if site starts as dark theme
// TODO animation doesn't work on contacts page
// TODO scroll bar not disappearing instantly after services block
// TODO Open/close transition as in design (pass via router????)
// TODO Different carousel behaviour with memory on interaction with item
// TODO portfolio carousel scroll won't work on touchscreens

// TODO animations are not proper
// TODO animate portfolio close button
// TODO mobile animations are not finalized

// NOW
// Fast scroll on services
// Close button not on the same level
// Nav going from top left corner
// mobile scaling during scroll
// close button not hiding animated portfolio
// header not showing on most pages
// social icons not animated on mobile
// buttons not animated on mobile: back must come from left, cross must come from right. "Close" - from top
// cross must not move on swipe left-right
// header z-index on page
// load from white
// after animation text moves to the left
// Tablets - bad adaptation
// Tablets - shows mobile icons
// Close leaving on desktop to portfolio item


let theme = {
  data() {
    return {
      theme: this.darkTheme
    }
  },
  computed: {
    darkTheme: {
      get() {
        return this.theme || localStorage.getItem('darkTheme') === 'true' ||
            (localStorage.getItem('darkTheme') === null && window.matchMedia('(prefers-color-scheme: dark)').matches);
      },
      set(value) {
        this.theme = value;
        localStorage.setItem('darkTheme', JSON.stringify(value));
      }
    }
  },
  methods: {
    switchTheme(v) {
      let style = document.documentElement.style;
      if (v) {
        style.setProperty('--bg',  '1, 4, 21');
        style.setProperty('--text-contrast',   '255, 255, 255');
      } else {
        style.setProperty('--bg',   '255, 255, 255');
        style.setProperty('--text-contrast',  '1, 4, 21');
      }
    }
  },
  mounted() {
    this.switchTheme(this.darkTheme);
  },
  watch: {
    darkTheme(v) {
      this.switchTheme(v);
    }
  },
}

// TODO migrate global variables to vuex

// Dirty hack
if (location.href.match('XBceKNbU8klgq3ivrrRc493XeFg9eUhYO22XpYht6Gt0EdsJl1jvgatoSQoV47TFtVpCxqvHtWIIaP1XqxajIkCudZbzLNh5oK9TagGPqo5coXXN4j72e7zgTgUewWIZ')) {
  new Vue({
    store,
    render: h => h(UptimeDashboard)
  }).$mount('#app')
} else {
  new Vue({
    router: createRouter(i18n),
    i18n,
    store,
    data() {
      return {
        dialogVisible: false, // use dialog visibility here as a global variable so as not to inject vuex
      }
    },
    mixins: [theme],
    methods: {
      showDialog(visible) {
        this.dialogVisible = visible
      }
    },
    render: h => h(App)
  }).$mount('#app')
}

